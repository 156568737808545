import Vue from 'vue'

import dayjs from 'dayjs'

import { ACTUAL_ROLE, CONNEXION_METHODE } from '@/utils/consts'

import { merge } from 'lodash'

import { initWhiteLabel } from '@/modules/whiteLabel.js'
import { getRoleByLocalStorage } from '@/utils/function'
import { sentryCustomMessage } from '@/interface/sentryInterface'

export default {
  SET_TYPE_MODE(state, type) {
    state.typeMode = type
  },
  SET_SEEP_LOGIN(state, newSteep) {
    state.steepLogin = newSteep
  },
  RESET_LOGIN_DATA(state) {
    state.login_data.method = null
    state.login_data.methods = []
    state.login_data.organisationId = null
    state.login_data.trainers = []
    state.login_data.ssoValue = ''
  },
  SET_LOGIN_METHODES(state, methodes) {
    state.login_data.methods = methodes
  },

  /** Stor all the data of a user */
  SET_LOGIN_ORGANISATION(state, trainer) {
    state.organization.id = trainer.organization
    state.login_data.organisationId = trainer.organization.id
  },
  SET_SPONSOR_LOGIN_ORGANISATION(state, sponsor) {
    state.organization.uuid = sponsor.organization
    state.login_data.organisationId = sponsor.organization_uuid
  },
  USER_LOGED(
    state,
    {
      token,
      trainer,
      uuid,
      organization_id,
      connexionMethode = CONNEXION_METHODE.UNKNOWN
    }
  ) {
    state.user = trainer
    state.token = token
    state.login_data.connexionMethode = connexionMethode
    localStorage.setItem('token', token)
    localStorage.setItem('organization_id', organization_id)
    localStorage.setItem('trainerId', trainer.id)
    localStorage.setItem('email', trainer.email)
    localStorage.setItem('date_login', dayjs().unix())
    localStorage.setItem('uuid', uuid)
    localStorage.setItem('connexionMethode', connexionMethode)
    sessionStorage.setItem('tokenSession', token)
    sessionStorage.setItem('trainerIdSession', trainer.id)
    sessionStorage.setItem('uuidSession', uuid)
    sessionStorage.setItem('organization_id', organization_id)
    sessionStorage.setItem('connexionMethode', connexionMethode)
  },
  SPONSOR_USER_LOGGED(
    state,
    {
      token,
      sponsor_user,
      sponsors,
      uuid,
      connexionMethode = CONNEXION_METHODE.UNKNOWN
    }
  ) {
    state.user = sponsors
    state.token = token
    state.login_data.connexionMethode = connexionMethode
    state.login_data.email = sponsor_user.email
    localStorage.setItem('token', token)
    localStorage.setItem('sponsorUuid', sponsor_user.uuid)
    localStorage.setItem('email', sponsor_user.email)
    localStorage.setItem('date_login', dayjs().unix())
    localStorage.setItem('uuid', uuid)
    localStorage.setItem('connexionMethode', connexionMethode)
    sessionStorage.setItem('tokenSession', token)
    sessionStorage.setItem('sponsorUuidSession', sponsor_user.uuid)
    sessionStorage.setItem('uuidSession', uuid)
    sessionStorage.setItem('connexionMethode', connexionMethode)
  },
  SET_CONNEXION_METHODE(state, connexionMethode) {
    state.login_data.connexionMethode = connexionMethode
    localStorage.setItem('connexionMethode', connexionMethode)
    sessionStorage.setItem('connexionMethode', connexionMethode)
  },
  SET_MICROSOFT_ACCOUNT(state, data) {
    state.currentMicrosoftAccount = data
  },
  USER_REFRESH(state, { trainer }) {
    state.user = trainer
  },
  SPONSOR_USER_REFRESH(state, sponsor) {
    state.user = sponsor
  },
  SET_USER_HAS_PASSWORD(state, flag) {
    state.user.has_password = flag
  },
  SET_SPONSOR_USER_HAS_PASSWORD(state, flag) {
    state.user.sponsor_user.has_password = flag
  },
  USER_LOGOUT(state) {
    state.user = null
    state.token = null
    sessionStorage.clear()
    localStorage.clear()
  },
  SET_TOKEN(state, { token }) {
    state.token = token
    localStorage.setItem('token', token)
    sessionStorage.setItem('tokenSession', token)
  },
  SET_USER_ID(state, { trainerId }) {
    state.userId = trainerId
    localStorage.setItem('trainerId', trainerId)
    sessionStorage.setItem('trainerIdSession', trainerId)
  },
  SET_HANDSHAKE(state) {
    localStorage.setItem(
      `${state.user.email}/timestampHandshake`,
      dayjs().unix()
    )
  },
  SET_SPONSOR_HANDSHAKE(state) {
    localStorage.setItem(
      `${state.user.sponsor_user.email}/timestampHandshake`,
      dayjs().unix()
    )
  },
  SET_ORGANIZATION(state, { organization }) {
    state.organization = organization
    localStorage.setItem('tmpOrganizationId', organization.id)
    localStorage.setItem('organization_id', organization.id)
    sessionStorage.setItem('organization_id', organization.id)

    initWhiteLabel(organization.configuration_directory)
  },
  SET_LOGOS(state, { logos }) {
    state.logos = logos
  },
  SET_TYPE_LOGIN(state, typeLogin) {
    state.login_data.type = typeLogin
  },
  SET_USER_EMAIL(state, userEmail) {
    state.login_data.email = userEmail
  },
  SET_USER_PASSWORD(state, userPassword) {
    state.login_data.password = userPassword
  },

  SET_CONNEXION_TYPE(state, newType) {
    state.login_data.type = newType
  },
  SET_LOGIN_ORGANIZATIONS(state, data) {
    if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) {
      state.login_data.trainers = data
    } else if (getRoleByLocalStorage() === ACTUAL_ROLE.SPONSOR) {
      state.login_data.sponsors = data
    }
  },
  SET_LOGIN_METHODE(state, method) {
    state.login_data.method = method
  },
  SET_LOGIN_ORGANIZATION(state, id) {
    state.login_data.organisationId = id
  },
  SET_SSO_TYPE(state, ssoValue) {
    state.login_data.ssoValue = ssoValue
  },

  SET_CUSTOM_STYLE(state, newCustomStyle) {
    state.customStyle = merge(state.customStyle, newCustomStyle)
  },

  SET_UUID(state, NewUuid) {
    state.uuid = NewUuid
  },

  SET_ERROR(state, { title, message }) {
    state.error = {
      title: title,
      message: message
    }
  },
  SET_FATAL_ERROR(state, error) {
    state.lastFatalEror = error
    Vue.$log.fatal(error)
    /*Vue.$crisp.push(["set", "session:data", [
              [
                  ["last_error", error],
                  ["last_error_message", error.message],
              ]
          ]]);*/
    sentryCustomMessage(error)
  },
  SET_CONNEXION_WITH_PASSWORD_OR_PIN(state, data) {
    state.connectionWithPasswordOrPin = data
  },
  SET_MICROSOFT_EMAIL_INVALID(state, bool) {
    state.isMicrosoftEmailInvalid = bool
  },
  SET_SPONSOR_ORGANIZATIONS_NAME(state, organizationsList) {
    state.sponsorOrganizationsName = organizationsList
  },
  SET_TRAINER_USER(state, trainerUser) {
    state.trainerUser = trainerUser
  },
  SET_IS_LAST_CONNECTED_ORGANIZATION(state, bool) {
    state.isLastConnectedOrganization = bool
  }
}
