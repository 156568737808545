import DefaultLayout from '@/layouts/DefaultLayout'
import { getRoleByLocalStorage } from '@/utils/function'
import { ACTUAL_ROLE } from '@/utils/consts'

export default [
  {
    path: '/newSection',
    name: 'AddSectionPage',
    meta: {
      title: 'newSection',
      layout: DefaultLayout
    },
    beforeEnter: (to, from, next) => {
      if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) next()
      else next('/NotFound404')
    },
    component: () => import('@/pages/AddSectionPage')
  }
]
