import api from '@/api/api'
import { commit } from 'lodash/seq'
import apiSponsor from '@/api/apiSponsor'
import { getRoleByLocalStorage } from '@/utils/function'

export default {
  /** ANIMATEUR **/

  getFiles(
    { commit, getters },
    {
      category,
      startDate,
      endDate,
      organization_uuid = undefined,
      trainings,
      sections,
      sessions,
      trainers,
      trainees,
      surveys
    }
  ) {
    return api.auth
      .get(`${getters.getUserType}/files`, {
        category: category ? category : null,
        'created_at[min]': startDate ? startDate : null,
        'created_at[max]': endDate ? endDate : null,
        training_id: trainings ? trainings : null,
        section_id: sections ? sections : null,
        session_id: sessions ? sessions : null,
        trainer_id: trainers ? trainers : null,
        trainee_id: trainees ? trainees : null,
        survey_id: surveys ? surveys : null
      })
      .then(result => {
        commit('SET_DOCUMENTS', result.items)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  downloadOneFile({ commit, getters }, uuidFile) {
    return api.auth
      .get(`${getters.getUserType}/files/${uuidFile}/download-link`)
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileBySections({ commit, getters }, searchSection) {
    return api.auth
      .get(
        `${getters.getUserType}/search-files/sections?keyword=${searchSection}`
      )
      .then(result => {
        commit('SET_SECTION', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileBySessions({ commit, getters }, searchSession) {
    return api.auth
      .get(
        `${getters.getUserType}/search-files/sessions?keyword=${searchSession}`
      )
      .then(result => {
        commit('SET_SESSION', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileByTrainings({ commit, getters }, searchTraining) {
    return api.auth
      .get(
        `${getters.getUserType}/search-files/trainings?keyword=${searchTraining}`
      )
      .then(result => {
        commit('SET_TRAINING', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileByTrainers({ commit, getters }, searchTrainer) {
    return api.auth
      .get(
        `${getters.getUserType}/search-files/trainers?keyword=${searchTrainer}`
      )
      .then(result => {
        commit('SET_TRAINER', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileByTrainees({ commit, getters }, searchTrainee) {
    return api.auth
      .get(
        `${getters.getUserType}/search-files/trainees?keyword=${searchTrainee}`
      )
      .then(result => {
        commit('SET_TRAINEE', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileBySurveys({ commit, getters }, searchSurveys) {
    return api.auth
      .get(
        `${getters.getUserType}/search-files/surveys?keyword=${searchSurveys}`
      )
      .then(result => {
        //format data received [{ id: 120, name: 'Survey1' }]
        let changeFormatSurvey = result.map(obj => {
          let newObj = {}
          newObj.id = obj.id
          newObj.display_name = obj.name
          return newObj
        })
        commit('SET_SURVEY', changeFormatSurvey)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  getFileInfo({ getters }) {
    return api.auth
      .get(`${getters.getUserType}/files/${id}`)
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  disableButton({ commit }, is_disabled) {
    commit('DISABLE_BUTTON', is_disabled)
  },

  //route not currently used

  // get({ getters }) {
  //   return api.auth
  //     .get(`${getters.getUserType}/files/${id}`)
  //     .then(result => {
  //       console.log(result)
  //       return Promise.resolve(result)
  //     })
  //     .catch(e => {
  //       return Promise.reject(e)
  //     })
  // }

  /** COMMANDITAIRE **/

  getFilesSponsor(
    { commit, getters },
    {
      category,
      startDate,
      endDate,
      organization_uuid,
      trainings,
      sections,
      sessions,
      trainers,
      trainees,
      surveys
    }
  ) {
    return apiSponsor.authSponsor
      .get(`${getRoleByLocalStorage().toLowerCase()}/files`, {
        category: category ? category : null,
        'created_at[min]': startDate ? startDate : null,
        'created_at[max]': endDate ? endDate : null,
        organization_uuid: organization_uuid ? organization_uuid : null,
        training_id: trainings ? trainings : null,
        section_id: sections ? sections : null,
        session_id: sessions ? sessions : null,
        trainer_id: trainers ? trainers : null,
        trainee_id: trainees ? trainees : null,
        survey_id: surveys ? surveys : null
      })
      .then(result => {
        commit('SET_DOCUMENTS', result.items)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  downloadOneFileSponsor({ commit, getters }, uuidFile) {
    return apiSponsor.authSponsor
      .get(
        `${getRoleByLocalStorage().toLowerCase()}/files/${uuidFile}/download-link`
      )
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileBySectionsSponsor({ commit, getters }, searchSection) {
    return apiSponsor.authSponsor
      .get(
        `${getRoleByLocalStorage().toLowerCase()}/search-files/sections?keyword=${searchSection}`
      )
      .then(result => {
        commit('SET_SECTION', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileBySessionsSponsor({ commit, getters }, searchSession) {
    return apiSponsor.authSponsor
      .get(
        `${getRoleByLocalStorage().toLowerCase()}/search-files/sessions?keyword=${searchSession}`
      )
      .then(result => {
        commit('SET_SESSION', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileByTrainingsSponsor({ commit, getters }, searchTraining) {
    return apiSponsor.authSponsor
      .get(
        `${getRoleByLocalStorage().toLowerCase()}/search-files/trainings?keyword=${searchTraining}`
      )
      .then(result => {
        commit('SET_TRAINING', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileByTrainersSponsor({ commit, getters }, searchTrainer) {
    return apiSponsor.authSponsor
      .get(
        `${getRoleByLocalStorage().toLowerCase()}/search-files/trainers?keyword=${searchTrainer}`
      )
      .then(result => {
        commit('SET_TRAINER', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileByTraineesSponsor({ commit, getters }, searchTrainee) {
    return apiSponsor.authSponsor
      .get(
        `${getRoleByLocalStorage().toLowerCase()}/search-files/trainees?keyword=${searchTrainee}`
      )
      .then(result => {
        commit('SET_TRAINEE', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  searchFileBySurveysSponsor({ commit, getters }, searchSurveys) {
    return apiSponsor.authSponsor
      .get(
        `${getRoleByLocalStorage().toLowerCase()}/search-files/surveys?keyword=${searchSurveys}`
      )
      .then(result => {
        //format data received [{ id: 120, name: 'Survey1' }]
        let changeFormatSurvey = result.map(obj => {
          let newObj = {}
          newObj.id = obj.id
          newObj.display_name = obj.name
          return newObj
        })
        commit('SET_SURVEY', changeFormatSurvey)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  getFileInfoSponsor({ getters }) {
    return apiSponsor.authSponsor
      .get(`${getRoleByLocalStorage().toLowerCase()}/files/${id}`)
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  }
}
