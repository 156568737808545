import Part from '@/model/Part'
import Vue from 'vue'

export default class Day {
  sequence
  section
  date
  parts = []

  constructor({ date, day_number, slots }, section) {
    this.sequence = day_number
    this.date = new Date(date)
    this.section = section
    for (let i = 0, length = slots.length; i < length; i++) {
      this.parts = [...this.parts, new Part(slots[i], this)]
    }
  }

  refresh({ slots }) {
    for (let i = 0, length = slots.length; i < length; i++) {
      let part = this.findPartBySequence(slots[i].sequence)
      if (!!part) {
        part.refresh(slots[i])
      } else {
        this.parts = [...this.parts, new Part(slots[i], this)]
      }
    }
  }

  findPartBySequence(sequence) {
    return this.parts.find(p => p.sequence === sequence)
  }
}
