import DefaultLayout from '@/layouts/DefaultLayout'

export default [
  {
    path: '/login',
    name: 'LoginPage',
    meta: {
      title: 'login',
      layout: DefaultLayout
    },
    component: () => import('@/pages/LoginPage')
  },
  {
    path: '/microsoft/connect',
    name: 'MicrosoftRedirectPage',
    meta: {
      title: 'microsoftConnect'
    },
    component: () => import('@/pages/MicrosoftConnectionPage')
  }
]
