import { ERROR_403 } from '@/utils/consts'

export const getOffset = el => {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  }
}

export const validEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const minTwoDigits = n => {
  return (n < 10 ? '0' : '') + n
}

export const phoneNumberRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/g

export const RegexLength = new RegExp('.{15,}'),
  RegexNumber = new RegExp('[0-9]+'),
  RegexLowercase = new RegExp('[a-z]+'),
  RegexUppercase = new RegExp('[A-Z]+'),
  RegexSpecialChars = new RegExp('\\W+') //Unused

export const getError = message => {
  switch (message) {
    case ERROR_403.NOT_VALIDATED.label:
      return ERROR_403.NOT_VALIDATED.message

    case ERROR_403.PIN_REQUEST_REQUIRED.label:
      return ERROR_403.PIN_REQUEST_REQUIRED.message

    case ERROR_403.WAITING_DELAY.label:
      return ERROR_403.WAITING_DELAY.message

    case ERROR_403.NOT_GENERATED.label:
      return ERROR_403.NOT_GENERATED.message

    case ERROR_403.EXPIRED.label:
      return ERROR_403.EXPIRED.message

    case ERROR_403.PIN_ALREADY_GENERATED.label:
      return ERROR_403.PIN_ALREADY_GENERATED.message

    case ERROR_403.NOT_VALID.label:
      return ERROR_403.NOT_VALID.message

    case ERROR_403.INVALID_CREDENTIALS.label:
      return ERROR_403.INVALID_CREDENTIALS.message

    case ERROR_403.PASSWORD_DISABLED.label:
      return ERROR_403.PASSWORD_DISABLED.message

    case ERROR_403.EXPIRED_TOKEN.label:
      return ERROR_403.EXPIRED_TOKEN.message

    case ERROR_403.TRAINER_NOT_FOUND.label:
      return ERROR_403.TRAINER_NOT_FOUND.message

    case ERROR_403.INVALID_TOKEN.label:
      return ERROR_403.INVALID_TOKEN.message

    default:
      return ERROR_403.ERROR.message
  }
}

export const getSponsorError = message => {
  switch (message) {
    case ERROR_403.INVALID_CREDENTIALS.label:
      return ERROR_403.INVALID_CREDENTIALS.sponsorMessage

    case ERROR_403.METHOD_NOT_ALLOWED.label:
      return ERROR_403.METHOD_NOT_ALLOWED.message
  }
}

export function getItem(item) {
  return localStorage.getItem(item) || sessionStorage.getItem(item)
}

export const filterByKey = (array, filter, key) => {
  return array.filter(
    entity => entity[key].toUpperCase().indexOf(filter.toUpperCase()) > -1
  )
}

/**
 * @param {Array} array
 * @param {String} keyObject
 * @returns {Array}
 */
export const sortOrReverse = (array, keyObject) => {
  if (JSON.stringify(array) === JSON.stringify(sortByKey(array, keyObject))) {
    return reverseByKey(array, keyObject)
  } else {
    return sortByKey(array, keyObject)
  }
}

/**
 * @param {Array} array
 * @param {String} keyObject
 * @returns {Array}
 */
function reverseByStringValueInArrayObject(array, keyObject) {
  return sortByStringValueInArrayObject(array, keyObject).reverse()
}

/**
 * @param {Array} array
 * @param {String} keyObject
 * @returns {Array}
 */
function sortByStringValueInArrayObject(array, keyObject) {
  return array.sort((a, b) => {
    const extensionA = a[keyObject].toUpperCase() // ignore upper and lowercase
    const extensionB = b[keyObject].toUpperCase() // ignore upper and lowercase
    if (extensionA < extensionB) {
      return -1
    }
    if (extensionA > extensionB) {
      return 1
    }
  })
}

/**
 * @param {Array} array
 * @param {String} key
 * @returns {Array}
 */
function sortByKey(array, key) {
  if (key === 'typeFile') {
    return sortByStringValueInArrayObject(array, 'extension')
  } else if (key === 'partner') {
    return array.sort((a, b) =>
      a['organization_uuid'].localeCompare(b['organization_uuid'])
    )
  } else {
    return array.sort((a, b) => a[key].localeCompare(b[key]))
  }
}

/**
 * @param {Array} array
 * @param {String} key
 * @returns {Array}
 */
function reverseByKey(array, key) {
  if (key === 'typeFile') {
    return reverseByStringValueInArrayObject(array, 'extension')
  } else if (key === 'partner') {
    return array
      .sort((a, b) =>
        a['organization_uuid'].localeCompare(b['organization_uuid'])
      )
      .reverse()
  } else {
    return array.sort((a, b) => a[key].localeCompare(b[key])).reverse()
  }
}

/**
 * @returns {string} - type of user: Trainer or Sponsor
 */
export const getRoleByLocalStorage = () => {
  return localStorage.getItem('type')
}

//When changing input type, the caret is going to the first char of the string
//This function, cross platform, is used to put the caret at the end of the string in the input
export const SetCaretAtEnd = elem => {
  var elemLen = elem.value.length
  // For IE Only
  if (document.selection) {
    // Set focus
    elem.focus()
    // Use IE Ranges
    var oSel = document.selection.createRange()
    // Reset position to 0 & then set at end
    oSel.moveStart('character', -elemLen)
    oSel.moveStart('character', elemLen)
    oSel.moveEnd('character', 0)
    oSel.select()
  } else if (elem.selectionStart || elem.selectionStart == '0') {
    // Firefox/Chrome
    elem.selectionStart = elemLen
    elem.selectionEnd = elemLen
    elem.focus()
  }
}

/**
 * @param {Array} array
 * @param element
 * @returns {boolean}
 */
export const isSingleElementInArray = (array, element) => {
  let tab = []
  array.forEach(function(el) {
    if (el[element]) {
      tab.push(el[element])
    }
  })
  return tab.length === 1
}

/**
 * @param {Object} organization
 * @param {String} oauth
 * @param {String} ssoName
 * @returns {boolean}
 */
export const isSsoAuthorize = (organization, oauth, ssoName) => {
  let isAuthorize = false
  organization.login_methods.forEach(function(el) {
    if (el.type === oauth && el.name === ssoName) {
      isAuthorize = true
    }
  })
  return isAuthorize
}

/**
 * Uppercase the first character in the `string`.
 * @param {String} string
 * @returns {String}
 */
export const ucFirst = string => {
  if (typeof string !== 'string') {
    return ''
  }
  if (string.length === 0) {
    return ''
  }
  return string[0].toUpperCase() + string.slice(1)
}

export const normalizeString = str => {
  return str
    .toLowerCase()
    .normalize('NFD') // Décomposer les caractères accentués en caractères de base + accents
    .replace(/[\u0300-\u036f]/g, '') // Supprimer les accents
}

/**
 * Valid for string array only
 * @param arrayStringWithDuplicates {string[]}
 * @returns {string[]}
 */
export const uniqueArrayString = arrayStringWithDuplicates => {
  return [...new Set(arrayStringWithDuplicates)]
}

/**
 * Remove value in array 1 matching with value in array 2. Only for primitives value
 * @param array1
 * @param array2
 * @returns {Array}
 */
export const removeMatchingValues = (array1, array2) => {
  const set2 = new Set(array2)
  return array1.filter(value => !set2.has(value))
}

export const filterCustomFields = (allCustomFields, entity) => {
  if (allCustomFields.length) {
    return allCustomFields.filter(item => item.entity === entity)
  }
}
