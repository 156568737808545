import i18n from '@/modules/i18n'

export const PAGINATION = 20
export const PAGINATION_TRAINEE = 10

export const TRAINEE_ACCESS_FROM_APP = {
  SEARCH: 'search',
  NONE: 'none',
  STRICT: 'strict'
}

export const STATUS = {
  AWAITED: {
    api: 'awaited',
    apiStatusSignature: 'missing',
    color: '#0d9cff',
    translation: i18n.t('utils.status.awaited')
  },
  AWAITED_ABS: {
    api: 'awaited',
    apiStatusSignature: 'absent',
    color: '#0d9cff',
    translation: i18n.t('utils.status.awaited')
  },
  PRESENT_NO_SIGNATURE: {
    api: 'present',
    apiStatusSignature: 'missing',
    color: '#FF9641',
    translation: i18n.t('utils.status.presentNoSignature')
  },
  PRESENT: {
    api: 'present',
    apiStatusSignature: 'signed',
    color: '#00C396',
    translation: i18n.t('utils.status.present')
  },
  ABSENT: {
    api: 'absent',
    apiStatusSignature: 'absent',
    color: '#FF3743',
    translation: i18n.t('utils.status.absent')
  },
  ABSENT_MISSING: {
    api: 'absent',
    apiStatusSignature: 'missing',
    color: '#FF3743',
    translation: i18n.t('utils.status.absent')
  },
  REPLACED: {
    api: 'replaced',
    apiStatusSignature: 'absent',
    color: '#424242',
    translation: i18n.t('utils.status.replaced')
  },
  REPLACED_MISSING: {
    api: 'replaced',
    apiStatusSignature: 'missing',
    color: '#424242',
    translation: i18n.t('utils.status.replaced')
  },
  REFUSED: {
    api: 'refused'
  }
}

export const MODAL_STATUS = {
  NONE: -1,
  EMERGE_TRAINER: 1,
  EMERGE_TRAINEE: 2,
  EMERGE_COLLECTIVE: 3,
  EMERGE_MAIL: 4,
  EMERGE_CALL: 5,
  EMERGE_DYNAMIC_QRCODE: 6
}

export const EMERGE_MODE = {
  NONE: -1,
  APP: 1,
  COLLECTIVE: 2,
  MAIL: 3,
  CALL: 4,
  QRCODE: 5
}

export const STATUS_TRAINING = {
  ONGOING: {
    api: 'ongoing',
    color: '#03a9f4',
    translation: i18n.t('utils.statusTraining.ongoing')
  },
  CANCELED: {
    api: 'canceled',
    color: '#FF3743',
    translation: i18n.t('utils.statusTraining.canceled')
  },
  SCHEDULED: {
    api: 'scheduled',
    color: '#bdbdbd',
    translation: i18n.t('utils.statusTraining.scheduled')
  },
  CLOSED: {
    api: 'closed',
    color: '#00C396',
    translation: i18n.t('utils.statusTraining.closed')
  }
}

export const ACTION = {
  COMMENT: 'comment',
  REMPLACE: 'remplace',
  SEND_MAIL: 'send_mail',
  DEPARTURE_EARLINESS: 'earlyDeparture',
  SIGN: 'sign',
  GAPS: 'gaps',
  RESIGN: 'resign',
  ABS: 'abs',
  DEL: 'del',
  EDIT: 'edit'
}

export const TOAST = {
  INFO: 'info',
  DANGER: 'danger',
  SUCCESS: 'success'
}

export const STATE = {
  NONE: 'none',
  SCHEDULED: 'scheduled',
  ONGOING: 'ongoing',
  CLOSED: 'closed',
  CANCELED: 'canceled'
}

export const ROLE = {
  TRAINER: 'trainer',
  TRAINEE: 'trainee'
}

export const LOGIN_STEEP = {
  NULL: 'null',
  MULTI_ORGANISATION: 'multi_organisation',
  MULTI_METHODE: 'multi_methode',
  END: 'end',
  GOTOINDEX: 'gotoindex',
  CODE: 'code',
  END_CODE: 'end_code',
  REDIRECT: 'redirectToIndex',
  ERROR: 'error',
  ENTREPRISE: 'entreprise',
  TOKEN_EXPIRED: 'token_expired'
}

export const LOGIN_ENTREPRISE = {
  S_CONNECT: '#connect',
  S_DEMO: '#demo'
}

export const TYPE_LOGIN = {
  EMAIL: 'email', //connexion with code
  PASSWORD: 'password',
  SSO: 'sso'
}

export const MODE_SENDING_CODE = {
  PIN_CODE: 'pin_code',
  PASSWORD: 'password',
  SSO: 'sso_on_premises',
  OAUTH: 'oauth'
}

export const CONNEXION_METHODE = {
  EMAIL: 'email',
  PASSWORD: 'password',
  SSO_MICROSOFT: 'SSO_microsoft',
  SSO_GOOGLE: 'SSO_google',
  SSO: 'sso',
  QR: 'Qr',
  MODE_COMPANY: 'company',
  TEAMS: 'teams',
  UNKNOWN: 'unknown',
  NAME: {
    GOOGLE: 'google',
    MICROSOFT: 'azure'
  }
}

export const SSO_VALUE = {
  GOOGLE: 'oauth.google',
  MICROSOFT: 'oauth.azure'
}

export const DAY_MODE = {
  ON_APP: 'ON_APP',
  BY_EMAIL: 'BY_EMAIL'
}

export const STATUS_EMAIL = {
  ERROR: 'ERROR',
  SEND: 'SEND',
  DELIVRED: 'DELIVRED',
  NONE: 'NONE'
}

export const PIN_REQUEST = {
  CONNECT: 'connect',
  PASSWORD_CHANGE: 'password_change',
  PASSWORD_DELETE: 'password_delete'
}

export const STATE_MODAL_PASSWORD = {
  PASSWORD_MANAGE: 'PASSWORD_MANAGE',
  PASSWORD_ADD_MANAGE: 'PASSWORD_ADD_MANAGE',
  PASSWORD_ADD_INPUT: 'PASSWORD_ADD_INPUT',
  PASSWORD_ADD_CODE: 'PASSWORD_ADD_CODE',
  PASSWORD_ADD_SUCCESS: 'PASSWORD_ADD_SUCCESS',
  PASSWORD_DELETE_MESSAGE: 'PASSWORD_DELETE_MESSAGE',
  PASSWORD_DELETE_CODE: 'PASSWORD_DELETE_CODE',
  PASSWORD_DELETE_SUCCESS: 'PASSWORD_DELETE_SUCCESS',
  PASSWORD_MODIFY_INPUT: 'PASSWORD_MODIFY_INPUT',
  PASSWORD_MODIFY_CODE: 'PASSWORD_MODIFY_CODE',
  PASSWORD_MODIFY_MESSAGE: 'PASSWORD_MODIFY_MESSAGE'
}

export const STATUS_SURVEY = {
  HOME: 'HOME',
  RESPONSE_SURVEY: 'RESPONSE_SURVEY',
  RESPONSE_TRAINEE: 'RESPONSE_TRAINEE',
  RESPONSE_PDF: 'RESPONSE_PDF',
  EVALUATION_SELECT: 'EVALUATION_SELECT',
  EVALUATION_TRAINEE_SELECT: 'EVALUATION_TRAINEE_SELECT',
  SURVEY_SELECT: 'SURVEY_SELECT',
  SURVEY_METHODE: 'SURVEY_METHODE',
  SURVEY_COLLECTIVE: 'SURVEY_COLLECTIVE',
  SURVEY_MAIL: 'SURVEY_MAIL'
}

export const COLLECTIVE_SIGNATURE = {
  ALWAYS: 'always',
  NEVER: 'never',
  TRAINER_CHOICE: 'trainer_choice'
}

export const ERROR_400 = {
  TEMPORAL_SIGNING_NOT_ALLOWED: {
    label: 'TEMPORAL_SIGNING_NOT_ALLOWED',
    message: i18n.t('utils.error.temporalSigningNotAllowed')
  },
  SECTION_NOT_ONGOING: {
    label: 'SECTION_NOT_ONGOING',
    message: i18n.t('utils.error.sectionNotOngoing')
  }
}

export const ERROR_403 = {
  NOT_VALIDATED: {
    label: 'NOT_VALIDATED',
    message: i18n.t('login.error.notValidated')
  },
  PIN_REQUEST_REQUIRED: {
    label: 'PIN_REQUEST_REQUIRED',
    message: i18n.t('login.error.pinRequestRequired')
  },
  WAITING_DELAY: {
    label: 'WAITING_DELAY',
    message: i18n.t('login.error.waitingDelay')
  },
  NOT_GENERATED: {
    label: 'NOT_GENERATED',
    message: i18n.t('login.error.notGenerated')
  },
  EXPIRED: {
    label: 'EXPIRED',
    message: i18n.t('login.error.expired')
  },
  PIN_ALREADY_GENERATED: {
    label: 'PIN_ALREADY_GENERATED',
    message: i18n.t('login.error.pinAlreadyGenerated')
  },
  NOT_VALID: {
    label: 'NOT_VALID',
    message: i18n.t('login.error.notValid')
  },
  INVALID_CREDENTIALS: {
    label: 'INVALID_CREDENTIALS',
    message: i18n.t('login.error.invalidCredentials'),
    sponsorMessage: i18n.t('login.error.invalidPassword')
  },
  PASSWORD_DISABLED: {
    label: 'PASSWORD_DISABLED',
    message: i18n.t('login.error.passwordDisabled')
  },
  ERROR: {
    label: 'ERROR',
    message: i18n.t('login.error.error')
  },
  EXPIRED_TOKEN: {
    label: 'EXPIRED_TOKEN',
    message: i18n.t('login.error.expiredToken')
  },
  TRAINER_NOT_FOUND: {
    label: 'Trainer could not be found',
    message: i18n.t('login.error.trainerNotFound')
  },
  INVALID_TOKEN: {
    label: 'INVALID_TOKEN',
    message: i18n.t('login.error.invalidToken')
  },
  METHOD_NOT_ALLOWED: {
    label: 'METHOD_NOT_ALLOWED',
    message: i18n.t('login.error.methodNotAllowed')
  },
  INVALID_ACCESS: {
    label: 'INVALID_ACCESS',
    message: i18n.t('login.error.invalidAccess')
  },
  INVALID_SSO_VALUE: {
    label: 'INVALID_SSO_VALUE',
    message: i18n.t('login.error.error')
  }
}

export const COMMON_ERROR = {
  ERROR_400: {
    label: i18n.t('commonError.title'),
    message: i18n.t('commonError.badRequest')
  },
  ERROR_401: {
    label: i18n.t('commonError.title'),
    message: i18n.t('commonError.forbidden')
  },
  ERROR_404: {
    label: i18n.t('commonError.title'),
    message: i18n.t('commonError.notFound')
  },
  ERROR_UNKNOWN: {
    label: i18n.t('commonError.title'),
    message: i18n.t('utils.error.unknown')
  }
}

export const SURVEY_TYPE = {
  SURVEY: 'survey',
  EVALUATION: 'evaluation'
}

//Used to set modal screen in SessionOverview. Need to be removed after replacing all old modal by bienvenue components modals.
export const SCREEN_SIZE = {
  MEDIUM: '800',
  LARGE: '1000',
  X_LARGE: '1200'
}

export const SEARCH_BUTTON_TYPE = {
  CHECKBOX: 'CHECKBOX',
  DATE: 'DATE',
  PERIOD: 'PERIOD'
}

export const SORT_METHOD_DOCUMENT = {
  NAME: 'name',
  CATEGORY: 'category',
  TYPEFILE: 'typeFile',
  PARTNER: 'partner'
}

export const CATEGORY_FILTER = [
  { id: 0, name: 'free' },
  { id: 1, name: 'group-compact-sheets' },
  { id: 2, name: 'pack-of-attendance-sheets' },
  { id: 3, name: 'pack-of-compact-sheets' },
  { id: 4, name: 'pack-of-achievement-certificate' },
  { id: 5, name: 'signed-document' },
  { id: 6, name: 'pack-of-signed-document' },
  { id: 7, name: 'survey-consolidation-sheet' },
  { id: 8, name: 'pack-of-survey-per-attendee-pdf' },
  { id: 9, name: 'survey-csv-results' }
]

export const ACTUAL_ROLE = {
  TRAINER: 'Trainer',
  SPONSOR: 'Sponsor'
}

export const SHA_TYPE = [
  { name: 'sha0', sha: 'SHA-0' },
  { name: 'sha1', sha: 'SHA-1' },
  { name: 'sha224', sha: 'SHA-224' },
  { name: 'sha256', sha: 'SHA-256' },
  { name: 'sha384', sha: 'SHA-384' },
  { name: 'sha512', sha: 'SHA-512' }
]

export const TRAINEE_SIGNING_MODE = {
  WITH_CALL: 'call_roll',
  AUTONOMY: 'autonomy',
  NOT_ALLOWED: 'not_allowed'
}

export const TRAINEE_VERIFICATION_MODE = {
  TEMPORAL_QR_CODE: 'temporal_qr_code',
  GEOLOCATION: 'geolocation',
  NONE: 'none'
}

export const SECTION_TYPE = {
  INTER: 'inter',
  INTRA: 'intra'
}

export const SECTION_MODE = [
  { type: 'on_site', label: i18n.t('updateSection.mode.on_site') },
  { type: 'remote', label: i18n.t('updateSection.mode.remote') },
  { type: 'hybrid', label: i18n.t('updateSection.mode.hybrid') }
]

export const PARAM_UPDATE_SECTION = {
  ROOT: '#root',
  CODE: 'code',
  NAME: 'name',
  TYPE: 'type',
  STAR_AT: 'start_at',
  END_AT: 'end_at',
  MORNING_START: 'morning_start',
  MORNING_END: 'morning_end',
  AFTERNOON_START: 'afternoon_start',
  AFTERNOON_END: 'afternoon_end',
  AUTO_CREAT_SEQUENCES: 'auto_create_sequence',
  MODE: 'mode',
  LOCATION: 'location',
  ADDRESS: 'address',
  ZIPCODE: 'zipcode',
  CITY: 'city',
  COUNTRY: 'country',
  SIGN_BEFORE_SLOT_START_DELAY: 'sign_before_slot_start_delay',
  TAGS: 'tags',
  TIMEZONE: 'timezone',
  SITE: 'site'
}

export const ORGANIZATION_EDIT_SECTION_RIGHTS = {
  EDIT_CODE: 'edit_code',
  EDIT_NAME: 'edit_name',
  EDIT_TYPE: 'edit_type',
  EDIT_PLANNING: 'edit_planning',
  EDIT_LOCATION: 'edit_location',
  EDIT_OPTIONS: 'edit_options',
  EDIT_TAGS: 'edit_tags',
  EDIT_SITE: 'edit_site'
}

export const PARAM_UPDATE_REGISTRATION = {
  ROOT: '#root',
  REFERENCE: 'reference',
  MEAL_INCLUDED: 'meal_included',
  FINANCING_METHOD: 'financing_method',
  CUSTOM_FIELDS: 'custom_fields'
}

export const ORGANIZATION_EDIT_REGISTRATION_RIGHTS = {
  EDIT_REFERENCE: 'edit_reference',
  EDIT_MEAL: 'edit_meal',
  EDIT_FINANCING: 'edit_financing',
  EDIT_CUSTOM_FIELDS: 'edit_custom_fields'
}

export const PART_NAME = {
  MORNING: 'Matin',
  AFTERNOON: 'Après-Midi'
}

export const CUSTOM_FIELD_ENTITY = {
  TRAINEE: 'trainee',
  TRAINEE_REGISTRATION: 'trainee_registration'
}
