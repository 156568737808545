export default {
  ADD_TOAST(state, params) {
    state.toasts.push(params)
  },
  REMOVE_TOAST(state, { id }) {
    state.toasts = state.toasts.filter(t => t.id !== id)
  },
  RESET_TOAST(state) {
    state.toasts = []
  },
  LOADING(state, loading) {
    state.loader = loading
  },
  TRANSITION_ANIMATION(state, transition) {
    state.transitionAnimation = transition
  },
  LOADING_BUTTON(state, is_loading_button) {
    state.is_loading_button = is_loading_button
  },
  LOADING_PORTAL_DOCUMENT_LIST(state, isLoading) {
    state.loadingPortalDocumentList = isLoading
  },
  LOADING_PORTAL_FILTER(state, isLoading) {
    state.loadingPortalFilter = isLoading
  },
  TAB_SHOW_LOADING_DOWNLOAD(state, tab) {
    state.tabShowLoadingDownload = tab
  },
  SET_BODY_SCROLL_LOCK(state, bodyScrollLock) {
    state.bodyScrollLock = bodyScrollLock
  },
  SET_ERROR(state, { title, message }) {
    state.error = {
      show: true,
      title,
      message
    }
  },
  SET_COMMON_ERROR(state, error) {
    state.commonError = error
  },
  CLOSE_ERROR(state) {
    state.error.show = false
  },
  SET_ERROR_DATA(state, errorData) {
    state.errorData = errorData
  }
}
