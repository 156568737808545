import { STATUS_SURVEY } from '@/utils/consts'

export default {
  current_surveys: [],
  current_evaluations: [],
  current_status: STATUS_SURVEY.HOME,
  old_status: null,
  current_select_survey: null,
  current_select_trainee: null,
  current_select_evaluation: null,
  errors: []
}
