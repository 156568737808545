export default class Part {
  start_at
  end_at
  sequence
  name
  duration
  status
  day

  constructor({ start_at, end_at, sequence, name, duration, status }, day) {
    this.start_at = start_at
    this.end_at = end_at
    this.sequence = sequence
    this.name = name
    this.duration = duration
    this.status = status
    this.day = day
  }

  refresh({ start_at, end_at, sequence, name, duration, status }) {
    this.start_at = start_at
    this.end_at = end_at
    this.sequence = sequence
    this.name = name
    this.duration = duration
    this.status = status
  }
}
