import api from '@/api/api'

export default {
  resetTrainings({ commit }) {
    commit('RESET')
  },
  getTrainings({ commit, rootState }, id) {
    return api.auth
      .get(`trainers/${id || rootState.auth.user.id}/days`)
      .then(result => {
        commit('SET_TRAININGS', result)
        return Promise.resolve(result)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  }
}
