import Da from 'vue2-datepicker/locale/es/da'

const monthNumberToString = number => {
  switch (number) {
    case 0:
      return 'utils.months.january'
    case 1:
      return 'utils.months.february'
    case 2:
      return 'utils.months.march'
    case 3:
      return 'utils.months.april'
    case 4:
      return 'utils.months.may'
    case 5:
      return 'utils.months.june'
    case 6:
      return 'utils.months.july'
    case 7:
      return 'utils.months.august'
    case 8:
      return 'utils.months.september'
    case 9:
      return 'utils.months.october'
    case 10:
      return 'utils.months.november'
    case 11:
      return 'utils.months.december'
  }
}

const dayNumberToStringShort = number => {
  switch (number) {
    case 0:
      return 'utils.daysShort.sunday'
    case 1:
      return 'utils.daysShort.monday'
    case 2:
      return 'utils.daysShort.tuesday'
    case 3:
      return 'utils.daysShort.wednesday'
    case 4:
      return 'utils.daysShort.thursday'
    case 5:
      return 'utils.daysShort.friday'
    case 6:
      return 'utils.daysShort.saturday'
  }
}

const timestampsSecondString = dateToTransform => {
  return parseInt(dateToTransform / 1000 + '')
}

const timeInSecond = date => {
  return date.getTime() / 1000
}

const timestampsSecondNow = () => {
  return timestampsSecondString(Date.now())
}

const formatedHour = hourToFormate => {
  return hourToFormate < 10 ? `0${hourToFormate}` : hourToFormate
}

const formatedMinute = minuteToFormate => {
  return minuteToFormate < 10 ? `0${minuteToFormate}` : minuteToFormate
}

const currentDayDate = () => {
  let date = new Date()
  date.setHours(0, 0, 0, 0)
  return date
}

const addDays = (date, days) => {
  let result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

const removeDays = (date, days) => {
  let result = new Date(date)
  result.setDate(result.getDate() - days)
  return result
}

/**
 * Return time difference between two dates.
 * @param {Date} dateFrom
 * @param {Date} dateTo
 * @param {('short'|'long')} [format] custom format, default is 'short'
 */
const dateDiff = (d1, d2, format = 'short') => {
  let delta = Math.abs(d2 - d1) / 1000

  let years = Math.floor(delta / 31536000) //To improve (add Leap year)
  delta -= years * 31536000

  let weeks = Math.floor(delta / 604800) % 52
  delta -= weeks * 604800

  let days = Math.floor(delta / 86400) % 7
  delta -= days * 86400

  let maxDays = Math.floor(delta / 86400)
  delta -= maxDays * 86400

  let hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  let minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  let seconds = Math.floor(delta % 60)

  let stringBuilder = ''

  if (maxDays) stringBuilder += `${maxDays} ${maxDays > 1 ? ' days' : ' day'}, `

  if (format === 'short') {
    stringBuilder += ` ${formattedTime(hours)}:${formattedTime(
      minutes
    )}:${formattedTime(seconds)}`
  } else if (format === 'tiny') {
    stringBuilder = ''

    if (years) return `${years} ${years > 1 ? ' years' : ' year'} `
    if (weeks) return `${weeks} ${weeks > 1 ? ' weeks' : ' week'} `
    if (days) return `${days} ${days > 1 ? ' days' : ' day'} `
    if (hours)
      return `${formattedTime(hours, 'short')} ${hours > 1 ? 'hours' : 'hour'} `
    if (minutes)
      return `${formattedTime(minutes, 'short')} ${
        minutes > 1 ? 'minutes' : 'minute'
      } `
    if (seconds)
      return `${formattedTime(seconds, 'short')} ${
        seconds > 1 ? 'seconds' : 'second'
      } `
    else return '0 second'
  } else {
    if (hours)
      stringBuilder += `${formattedTime(hours, 'short')} ${
        hours > 1 ? 'hours' : 'hour'
      } `

    if (minutes)
      stringBuilder += `${formattedTime(minutes, 'short')} ${
        minutes > 1 ? 'minutes' : 'minute'
      } `

    if (seconds)
      stringBuilder += `${formattedTime(seconds, 'short')} ${
        seconds > 1 ? 'seconds' : 'second'
      } `
  }

  return stringBuilder
}

function formattedTime(value, format = 'long') {
  if (format === 'long') return value < 10 ? `0${value}` : value
  else return value < 10 ? `${value}` : value
}

function daysLeftBeforeExpire(d1, d2) {
  //Return the numbers of days between 2 dates
  let delta = Math.abs(d1 - d2) / 1000

  let days = Math.floor(delta / 86400)

  return days
}

function combineDateWithTime(date, time) {
  date = new Date(date)
  time = new Date('01/01/1970 ' + time)

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  )
}

//format date received "01/06/2023"
function formatDateToIso8601(date) {
  let DateParsed = date.split('/').reverse()
  let year = DateParsed[0]
  let month = parseInt(DateParsed[1]) - 1
  let day = DateParsed[2]
  return new Date(year, month, day).toISOString().slice(0, -5)
}

//format timestamp (!in millisecond) received
function formatTimestampToIso8601(timestamp) {
  return new Date(timestamp).toISOString()
}

//format date + time received "01-06-2023" + 01:00
function formatDatePlusTimeToIso8601(date, time) {
  let dateObj = new Date(date + ' ' + time + ':00')
  return new Date(dateObj).toISOString()
}

//get month, day, hours or minutes with 2 digits
function addZeroBefore(number) {
  return (number < 10 ? '0' : '') + number
}

//get only date without hours and time from Iso8601
function getDateByDateIso8601(dateIso8601) {
  const dateObj = new Date(dateIso8601)
  const year = dateObj.getFullYear()
  const month = addZeroBefore(dateObj.getMonth() + 1)
  const day = addZeroBefore(dateObj.getDate())
  return `${year}-${month}-${day}`
}

//get hours and minute from date Iso8601
function getTimeByDateIso8601(dateIso8601) {
  const dateObj = new Date(dateIso8601)
  const hour = addZeroBefore(dateObj.getHours())
  const minute = addZeroBefore(dateObj.getMinutes())
  return `${hour}:${minute}`
}

export {
  formatedHour,
  formatedMinute,
  monthNumberToString,
  dayNumberToStringShort,
  timestampsSecondNow,
  timestampsSecondString,
  currentDayDate,
  addDays,
  removeDays,
  timeInSecond,
  dateDiff,
  getTimeByDateIso8601,
  getDateByDateIso8601,
  daysLeftBeforeExpire,
  combineDateWithTime,
  formatDateToIso8601,
  formatTimestampToIso8601,
  formatDatePlusTimeToIso8601
}
