import Session from '@/model/Session'
import Day from '@/model/Day'
import Attendee from '@/model/Attendee'
import Vue from 'vue'

import { ROLE } from '@/utils/consts'

export default class Section {
  id
  status
  remote_id
  address
  name
  auto_create_teams_channel
  city
  country
  duration
  duration_as_float
  duration_in_minutes
  location
  session
  start_at
  end_at
  day
  attendees = []
  customFields = []

  constructor(section, currentDay, customFields) {
    this.id = section.id
    this.status = section.status
    this.remote_id = section.code
    this.address = section.address
    this.city = section.city
    this.name = section.name
    this.auto_create_teams_channel = section.auto_create_teams_channel
    this.country = section.country
    this.duration = section.duration
    this.duration_as_float = section.duration_as_float
    this.duration_in_minutes = section.duration_in_minutes
    this.location = section.location
    this.start_at = section.start_at
    this.end_at = section.end_at
    this.customFields = customFields.map(field =>
      field.type === 'checkbox'
        ? { value: false, ...field }
        : { value: '', ...field }
    )
    this.day = new Day(
      section.planning.days.find(d => d.day_number === parseInt(currentDay)),
      this
    )
    this.session = new Session(section.session)
    this.setAttendees(section.trainee_attendances, ROLE.TRAINEE)
    this.setAttendees(section.trainer_attendances, ROLE.TRAINER)
  }

  setAttendees(attendees, role) {
    for (let i = 0, length = attendees.length; i < length; i++) {
      this.setAttendee(attendees[i], role)
    }
  }

  refreshAttendees(attendees, role) {
    for (let i = 0, length = attendees.length; i < length; i++) {
      this.refreshAttendee(attendees[i], role)
    }
  }

  setAttendee(newAttendee, role) {
    const dayAttendee = newAttendee.day
    if (parseInt(dayAttendee) !== parseInt(this.day.sequence)) {
      return
    }
    let attendee = this.attendees.find(
      a => a.local_id === `${role}_${newAttendee[role].id}`
    )
    if (!attendee) {
      attendee = new Attendee(newAttendee, role, this)
      this.attendees = [...this.attendees, attendee]
    }
    attendee.addAttendance(
      newAttendee,
      this.day.findPartBySequence(newAttendee.sequence)
    )
  }

  refreshAttendee(refreshAttendee, role) {
    const dayAttendee = refreshAttendee.day
    if (parseInt(dayAttendee) !== parseInt(this.day.sequence)) {
      return
    }

    let attendee = this.attendees.find(a => a.id === refreshAttendee[role].id)
    if (!attendee) {
      attendee = new Attendee(refreshAttendee, role, this)
      this.attendees = [...this.attendees, attendee]

      attendee.addAttendance(
        refreshAttendee,
        this.day.findPartBySequence(refreshAttendee.sequence)
      )
    } else {
      attendee.refresh(refreshAttendee, role)

      let attendance = attendee.getAttendanceById(refreshAttendee.id)
      if (!attendance) {
        attendee.addAttendance(
          refreshAttendee,
          this.day.findPartBySequence(refreshAttendee.sequence)
        )
      } else {
        attendance.refresh(refreshAttendee)
      }
    }
  }

  getAttendeesByRole(role) {
    return this.attendees.filter(a => a.role === role)
  }

  getAttendancesByRoleAndPart(role, part) {
    return this.getAttendeesByRole(role).reduce((list, a) => {
      const attendance = a.getAttendanceByPart(part)
      if (attendance) return [...list, attendance]
      return list
    }, [])
  }

  getAttendancesByRoleAndPartAndStatus(role, part, status = []) {
    return this.getAttendancesByRoleAndPart(role, part).filter(
      a => status.includes(a.status) //&& status.includes(a.status)
    )
  }

  getAttendancesByRoleAndPartAndStatusAndStatusSignature(
    role,
    part,
    status = []
  ) {
    let fullList = this.getAttendancesByRoleAndPart(role, part)
    let resList = []

    status.forEach(statusItem => {
      fullList.forEach(item => {
        if (
          statusItem.api === item.status &&
          statusItem.apiStatusSignature === item.signature_status
        ) {
          if (!resList.includes(item)) {
            resList.push(item)
          }
        }
      })
    })

    return resList
  }

  getAttendancesByRoleAndPartAndNotStatus(role, part, status = []) {
    return this.getAttendancesByRoleAndPart(role, part).filter(
      a => !status.includes(a.status)
    )
  }

  getTrainerAttendees() {
    return this.attendees.filter(a => a.role === ROLE.TRAINER)
  }

  getTraineeAttendees() {
    return this.attendees.filter(a => a.role === ROLE.TRAINEE)
  }

  findAttendanceById(idAttendance) {
    for (let i = 0, length = this.attendees.length; i < length; i++) {
      const attendance = this.attendees[i].attendances.find(
        a => a.id === idAttendance
      )
      if (!!attendance) {
        return attendance
      }
    }
  }

  findAttendanceByRegistrationId(idRegistration) {
    for (let i = 0, length = this.attendees.length; i < length; i++) {
      const attendance = this.attendees[i].attendances.find(
        a => a.registration_id === parseInt(idRegistration)
      )
      if (!!attendance) {
        return attendance
      }
    }
  }

  findAttendee(idAttendee) {
    return this.attendees.find(a => a.id === idAttendee)
  }

  findAttendance(idAttendance) {
    for (let i = 0, length = this.attendees.length; i < length; i++) {
      const attendances = this.attendees[i].attendances.find(
        a => a.id === idAttendance
      )
      if (!!attendances) return attendances
    }
    return null
  }

  updateTrainerAttendee(updatedTrainer, sequence, trainer) {
    let attendance = trainer.getAttendanceByPart(sequence)
    trainer.updated_at = updatedTrainer.updated_at
    attendance.meal_included = updatedTrainer.meal_included
    attendance.signed_at = updatedTrainer.signed_at
    attendance.signature_status = updatedTrainer.signature_status
    attendance.signature_url = updatedTrainer.signature_url
    attendance.status = updatedTrainer.status
    attendance.arrival_lateness = updatedTrainer.arrival_lateness
    attendance.departure_earliness = updatedTrainer.departure_earliness
  }

  updateTraineeAttendee(updatedTrainee, sequence, trainee) {
    let attendance = trainee.getAttendanceByPart(sequence)
    trainee.updated_at = updatedTrainee.updated_at
    attendance.meal_included = updatedTrainee.meal_included
    attendance.signed_at = updatedTrainee.signed_at
    attendance.signature_url = updatedTrainee.signature_url
    attendance.signature_status = updatedTrainee.signature_status
    attendance.status = updatedTrainee.status
    attendance.arrival_lateness = updatedTrainee.arrival_lateness
    attendance.departure_earliness = updatedTrainee.departure_earliness
  }

  addTrainee(attendance_trainees) {
    this.setAttendees(attendance_trainees, ROLE.TRAINEE)
  }

  nbAbsent() {
    return this.attendees.reduce((count, a) => {
      return count + a.nbAttendanceInAbsent()
    }, 0)
  }

  nbAttendance() {
    return this.attendees.reduce((count, a) => {
      return count + a.nbAttendance()
    }, 0)
  }

  purcentageAbsent() {
    return (100 * this.nbAbsent()) / this.nbAttendance()
  }
}
