export default {
  toasts: state => state.toasts,
  loader: state => state.loader,
  loadingPortalDocumentList: state => state.loadingPortalDocumentList,
  loadingPortalFilter: state => state.loadingPortalFilter,
  transitionAnimation: state => state.transitionAnimation,
  is_loading_button: state => state.is_loading_button,
  tabShowLoadingDownload: state => state.tabShowLoadingDownload,
  getbodyScrollLock: state => state.bodyScrollLock,
  error: state => state.error,
  getCommonError: state => state.commonError,
  errorData: state => state.errorData
}
