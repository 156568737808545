import { STATUS } from '@/utils/consts'
import Attendance from './Attendance'

export default class Attendee {
  id
  local_id
  remote_id
  email
  establishment
  firstname
  lastname
  phone
  address
  city
  country
  zipcode
  title
  updated_at
  role
  section
  attendances = []
  registration_code
  // UI/UX needed property
  detail

  constructor(attendee, role, section) {
    const {
      id,
      title,
      lastname,
      firstname,
      email,
      establishment,
      phone,
      address,
      city,
      country,
      zipcode,
      updated_at,
      remote_id
    } = attendee[role]
    this.id = id
    this.local_id = `${role}_${id}`
    this.remote_id = remote_id
    this.email = email
    this.establishment = establishment
    this.firstname = firstname
    this.lastname = lastname
    this.phone = phone
    this.address = address
    this.city = city
    this.country = country
    this.zipcode = zipcode
    this.title = title
    this.updated_at = updated_at
    this.role = role
    this.section = section
    this.registration_code = attendee.registration_code
    this.detail = false
  }

  refresh(attendee, role) {
    const {
      updated_at,
      phone,
      address,
      city,
      country,
      zipcode,
      title,
      lastname,
      firstname,
      remote_id,
      email,
      establishment
    } = attendee[role]
    this.remote_id = remote_id
    this.email = email
    this.establishment = establishment
    this.firstname = firstname
    this.lastname = lastname
    this.phone = phone
    this.address = address
    this.city = city
    this.country = country
    this.zipcode = zipcode
    this.title = title
    this.updated_at = updated_at
  }

  addAttendance(attendance, part) {
    this.attendances = [
      ...this.attendances,
      new Attendance(attendance, part, this)
    ]
  }

  getAttendanceByPart(part) {
    return this.attendances.find(a => a.part.sequence === part)
  }

  getAttendanceById(id) {
    return this.attendances.find(a => a.id === id)
  }

  setStatusInAttendances(newStatus) {
    this.attendances.forEach(a => (a.status = newStatus))
  }

  nbAttendance() {
    return this.attendances.length
  }

  nbAttendanceInAbsent() {
    return this.attendances.reduce((count, a) => {
      if (a.status === STATUS.ABSENT.api) return count + 1
      return count
    }, 0)
  }

  changeAllStatus(status) {
    this.attendances.map(a => (a.status = status))
  }
}
