import { TOAST } from '@/utils/consts'

export default {
  addToastInfo({ dispatch }, label) {
    dispatch('addToast', { label, type: TOAST.PRIMARY })
  },
  addToast({ commit }, { label, type = 'primary' }) {
    const uuid =
      new Date().getTime().toString(36) +
      Math.random()
        .toString(36)
        .substr(2, 9)
    let time = setTimeout(() => {
      commit('REMOVE_TOAST', { id: uuid })
      clearTimeout(time)
    }, 2000)
    commit('ADD_TOAST', { id: uuid, label: label, type: type })
  },
  removeToast({ commit }, { id }) {
    commit('REMOVE_TOAST', { id })
  },
  resetToast({ commit }) {
    commit('RESET_TOAST')
  },
  loading({ commit }, loading) {
    commit('LOADING', loading)
  },
  loadingPortalDocumentList({ commit }, loading) {
    commit('LOADING_PORTAL_DOCUMENT_LIST', loading)
  },
  loadingPortalFilter({ commit }, loading) {
    commit('LOADING_PORTAL_FILTER', loading)
  },
  tabShowLoadingDownload({ commit }, tab) {
    commit('TAB_SHOW_LOADING_DOWNLOAD', tab)
  },
  transitionAnimation({ commit }, transition) {
    commit('TRANSITION_ANIMATION', transition)
  },
  is_loading_button({ commit }, is_loading_button) {
    commit('LOADING_BUTTON', is_loading_button)
  },
  setError({ commit }, { title, message }) {
    commit('SET_ERROR', { title, message })
  },
  setErrorData({ commit }, errorData) {
    commit('SET_ERROR_DATA', errorData)
  },
  setCommonError({ commit }, commonError) {
    commit('SET_COMMON_ERROR', commonError)
  },
  closeError({ commit }) {
    commit('CLOSE_ERROR')
  }
}
