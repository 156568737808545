import api from '@/api/api'
import { PAGINATION, PAGINATION_TRAINEE } from '@/utils/consts'

export default {
  getTrainings({ commit, rootState }, { searchString, page }) {
    return api.auth
      .get(`trainings`, {
        search_term: searchString,
        page: page,
        limit: PAGINATION
      })
      .then(result => {
        commit('SET_MAX_TRAININGS', result.total)
        commit('SET_TRAININGS', result.items)
        return Promise.resolve(result.items)
      })
      .catch(() => {
        return Promise.reject()
      })
  },
  getSessions({ commit, rootState }, { trainingId, searchString, page }) {
    return api.auth
      .get(`sessions`, {
        training_id: trainingId,
        search_term: searchString,
        page: page,
        limit: PAGINATION
      })
      .then(result => {
        commit('SET_MAX_SESSIONS', result.total)
        commit('SET_SESSIONS', result.items)
        return Promise.resolve(result.items)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setTraineeCash({ commit, rootState }, newList) {
    commit('SET_TRAINEE', newList)
  },
  getTrainee({ commit, rootState }, { searchTerm, page }) {
    return api.auth
      .get(`trainees`, {
        search_term: searchTerm,
        page: page,
        limit: PAGINATION_TRAINEE
      })
      .then(({ items, total }) => {
        commit('SET_MAX_TRAINEE', total)
        commit('SET_TRAINEE', items)

        return Promise.resolve(items)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setTrainings({ commit, rootState }, { title, code }) {
    if (code === '') {
      code = 'auto'
    }

    return api.auth
      .post(`trainings`, {
        title: title,
        code: code
      })
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setSession({ commit, rootState }, { trainingId, code, startAt, endAt }) {
    if (code === '') {
      code = 'auto'
    }
    return api.auth
      .post(`sessions`, {
        training_id: trainingId,
        code: code,
        start_at: startAt,
        end_at: endAt
      })
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  async setSection(
    { commit, rootState },
    {
      sessionId,
      code,
      location,
      address,
      zipcode,
      city,
      country,
      startAt,
      endAt
    }
  ) {
    if (code === '') {
      code = 'auto'
    }
    return new Promise((resolve, reject) => {
      api.auth
        .post(`sections`, {
          session_id: sessionId,
          code: code,
          location: location,
          address: address,
          zipcode: zipcode,
          city: city,
          country: country,
          start_at: startAt,
          end_at: endAt
        })
        .then(result => {
          return resolve(result)
        })
        .catch(error => {
          return reject(error)
        })
    })
  },
  resetTrainee({ commit }) {
    commit('SET_TRAINEE', [])
    commit('SET_MAX_TRAINEE', 0)
  }
}
