export default {
  SET_DAYS(state, days) {
    state.days = days
  },
  ADD_DAY(state, day) {
    state.days.push(day)
  },
  UPDATE_DAY(state, { newDay, slots }) {
    state.days.find(
      t =>
        t.section_id === newDay.section_id && t.day_number === newDay.day_number
    ).slots = slots
  }
}
