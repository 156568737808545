import { ROLE } from '@/utils/consts'

import Section from '@/model/Section'

export default {
  SET_CURRENT_PART(state, part) {
    state.currentPart = part
  },
  SET_CURENT_STATE_DAY(state, status) {
    state.current_state_day = status
  },
  SET_CURENT_STATE_SLOT(state, status) {
    state.current_state_slot = status
  },
  SET_STATE_DAY(state, status) {
    state.state_day = status
  },

  SET_ATTENDEES_TO_SIGN(state, attendees) {
    state.attendeesToSign = attendees
  },
  SET_SECTION(state, { section, day, customFields }) {
    state.section = new Section(section, day, customFields)
  },
  SET_SECTION_SIMPLE(state, section) {
    state.sectionSimple = section
  },
  REFRESH_SECTION(state, { section, day }) {
    state.section.refresh(section, day)
  },
  RESET_SECTION(state) {
    state.section = null
    state.showEmergeOnApp = false
  },
  SET_DAY(state, day) {
    state.dayNumber = parseInt(day)
  },
  RESET_DAY(state) {
    state.dayNumber = 0
  },
  SET_EMAIL_ATTENDEE(state, { emailAttendees }) {
    state.emailAttendeesError = []
    for (let i = 0, length = emailAttendees.length; i < length; i++) {
      let attendance = state.section.findAttendanceById(
        emailAttendees[i].attendance_id
      )
      if (!!attendance && emailAttendees[i].success) {
        attendance.emailSend = 'send'
      }
      if (!!attendance && !emailAttendees[i].success) {
        attendance.emailSend = 'failed'
      }
    }
  },
  UPDATE_ATTENDEE(state, { personUpdated, role }) {
    if (role === ROLE.TRAINER) {
      let trainer = state.section
        .getTrainerAttendees()
        .find(t => t.id === personUpdated.trainer_id)

      if (trainer != null) {
        state.section.updateTrainerAttendee(
          personUpdated,
          personUpdated.sequence,
          trainer
        )
      }
    }
    if (role === ROLE.TRAINEE) {
      let trainee = state.section
        .getTraineeAttendees()
        .find(t => t.id === personUpdated.trainee_id)
      if (trainee != null) {
        state.section.updateTraineeAttendee(
          personUpdated,
          personUpdated.sequence,
          trainee
        )
      }
    }
  },
  SET_EMERGE_ON_APP(state, flag) {
    state.showEmergeOnApp = flag
  },
  ADD_TRAINEE_TO_SECTION(state, { attendances_trainee }) {
    state.section.addTrainee(attendances_trainee)
  },

  SHOW_PERSON_DETAIL(state, { flag, id }) {
    state.section.findAttendee(id).detail = flag
  },
  SET_COLLECTIVE_LINK(state, link) {
    state.collectiveLink = link
  },
  SET_CODE_QR(state, code) {
    state.codeQR = code
  },
  SET_IS_SECTION_END_DATE_EDITED(state, bool) {
    state.isSectionEndDateEdited = bool
  },
  SET_UPDATED_SECTION(state, section) {
    state.updatedSection.doNotEdit.id = section.id
    state.updatedSection.editCode.code = section.code
    state.updatedSection.editName.name = section.name
    state.updatedSection.editType.type = section.type
    state.updatedSection.editPlanning.start_at = section.start_at
    state.updatedSection.editPlanning.end_at = section.end_at
    state.updatedSection.editPlanning.morning_start =
      section.planning.days[0].slots[0].start_at
    state.updatedSection.editPlanning.morning_end =
      section.planning.days[0].slots[0].end_at
    if (section.planning.days[0].slots[1]) {
      state.updatedSection.editPlanning.afternoon_start =
        section.planning.days[0].slots[1].start_at
      state.updatedSection.editPlanning.afternoon_end =
        section.planning.days[0].slots[1].end_at
    }
    state.updatedSection.editPlanning.auto_create_sequences =
      section.auto_create_sequences
    state.updatedSection.editLocation.mode = section.mode
    state.updatedSection.editLocation.location = section.location
    state.updatedSection.editLocation.address = section.address
    state.updatedSection.editLocation.zipcode = section.zipcode
    state.updatedSection.editLocation.city = section.city
    state.updatedSection.editLocation.country = section.country
    state.updatedSection.editOptions.sign_before_slot_start_delay =
      section.sign_before_slot_start_delay
    state.updatedSection.editTags.tags = section.tags
    state.updatedSection.editOptions.site = {
      id: section.site_id,
      name: section.site_name
    }
    state.updatedSection.editPlanning.timezone = section.timezone
  },

  SET_UPDATED_TRAINEE_REGISTRATION(state, traineeRegistration) {
    state.updateTraineeRegistration.id = traineeRegistration.id
    state.updateTraineeRegistration.reference = traineeRegistration.reference
    state.updateTraineeRegistration.meal_included =
      traineeRegistration.meal_included
    state.updateTraineeRegistration.financing_method =
      traineeRegistration.financing_method
  },
  SET_IS_UPDATE_REGISTRATION_MODAL(state, bool) {
    state.isUpdateRegistrationModal = bool
  },
  SET_TRAINEE_INFO(state, { firstname, lastname }) {
    state.traineeInfo.firstname = firstname
    state.traineeInfo.lastname = lastname
  },
  SET_FILTERED_CUSTOM_FIELDS(
    state,
    { filteredCustomFields, traineeRegistrationId }
  ) {
    state.filteredCustomFields.traineeRegistrationId = traineeRegistrationId
    state.filteredCustomFields.arrayFields = filteredCustomFields
  },
  SET_VALUE_CUSTOM_FIELDS(state, ValueCustomFields) {
    state.valueCustomFields = ValueCustomFields
  }
}
