import api from '@/api/api'

import { addDays, currentDayDate, removeDays, timeInSecond } from '@/utils/date'

export default {
  resetDays({ commit }) {
    commit('SET_TRAININGS', [])
  },
  getDays({ commit, rootState }, id) {
    let currentDay = removeDays(currentDayDate(), 2)
    let currentMaxFilterDay = addDays(currentDayDate(), 2)
    commit('SET_DAYS', [])
    return api.auth
      .get(`trainers/${id}/days`)
      .then(daysResult => {
        commit('SET_DAYS', [])
        daysResult.forEach(dayItem => {
          if (
            dayItem.day_date > timeInSecond(currentDay) &&
            dayItem.day_date < timeInSecond(currentMaxFilterDay)
          ) {
            dayItem.slots = null
            commit('ADD_DAY', dayItem)
            return api.auth
              .get(`sections/${dayItem.section_id}`)
              .then(newSection => {
                commit('UPDATE_DAY', {
                  newDay: dayItem,
                  slots: newSection.planning.days.find(
                    tday => tday.day_number === dayItem.day_number
                  ).slots
                })
              })
              .catch(e => {
                dayItem.slots = null
                commit('UPDATE_DAY', { newDay: dayItem, slots: null })
              })
          } else {
            dayItem.slots = null
            commit('ADD_DAY', dayItem)
          }
        })
        return Promise.resolve()
      })
      .catch(e => {
        return Promise.reject(e)
      })
  }
}
