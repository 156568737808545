export default {
  trainers: (state, getters, rootState) =>
    rootState.section.section.getTrainerAttendees() || [],
  trainees: (state, getters, rootState) =>
    rootState.section.section.getTraineeAttendees() || [],

  newTraineeId: state => state.newTraineeId,

  // Get Trainee Attendances from section for the selected day
  traineeAttendances: (state, getters, rootState) =>
    !!rootState.section.section ? getters.trainees : [],

  //tmp valu of section planning
  fullSectionsPlanning: state => state.fullSectionsPlanning,
  loading: state => state.loading
}
