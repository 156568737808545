import { PublicClientApplication } from '@azure/msal-browser'

const isProduction = process.env.NODE_ENV === 'production'

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MICROSOFT_APPLICATION_ID,
    authority: 'https://login.microsoftonline.com/common',
    postLogoutRedirectUri: process.env.VUE_APP_BASE_URL + '/login'
  },
  cache: {
    cacheLocation: 'sessionStorage'
  }
}

export const msalInstance = new PublicClientApplication(msalConfig)
