import axios from 'axios'
import router from '@/router'
import store from '@/store'

import { LOGIN_STEEP } from '@/utils/consts'

class HttpClient {
  constructor(config, useInterceptors = true) {
    this.instance = axios.create({
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFToken',
      baseURL: process.env.VUE_APP_BASE_URL,
      ...config
    })

    this.useInterceptors = useInterceptors

    if (this.useInterceptors)
      this.instance.interceptors.response.use(
        response => {
          return response
        },
        error => {
          switch (error?.response?.status) {
            case undefined:
              error = {
                response: {
                  data: {
                    errors: [error?.message]
                  }
                },
                isCancel: axios.isCancel(error)
              }
              break

            case 500:
              error.response.data = { errors: ['500 Server error'] }
              break

            case 401:
              if (error?.response.data.detail === 'Token expired') {
                localStorage.clear()
                sessionStorage.clear()
                store.commit('auth/SET_SEEP_LOGIN', LOGIN_STEEP.TOKEN_EXPIRED)

                router.push({ path: '/login' }).catch(() => {})
              }

            default:
              break
          }
          return Promise.reject(error)
        }
      )
  }

  get(path, config) {
    return this.instance.get(path, { ...config })
  }

  delete(path, config) {
    return this.instance.delete(path, { ...config })
  }

  post(path, data, config) {
    return this.instance.post(path, data, { ...config })
  }

  put(path, data, config) {
    return this.instance.put(path, data, { ...config })
  }

  patch(path, data, config) {
    return this.instance.patch(path, data, { ...config })
  }
}

export default HttpClient
