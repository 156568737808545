export default class Training {
  id
  code
  entity_name
  nb_of_days
  on_site
  technical_sheat
  title
  type
  updated_at

  constructor({
    id,
    code,
    entity_name,
    nb_of_days,
    on_site,
    technical_sheat,
    title,
    updated_at
  }) {
    this.id = id
    this.code = code
    this.entity_name = entity_name
    this.nb_of_days = nb_of_days
    this.on_site = on_site
    this.technical_sheat = technical_sheat
    this.title = title
    this.updated_at = updated_at
  }

  refresh({
    code,
    entity_name,
    nb_of_days,
    on_site,
    technical_sheat,
    title,
    updated_at
  }) {
    this.code = code
    this.entity_name = entity_name
    this.nb_of_days = nb_of_days
    this.on_site = on_site
    this.technical_sheat = technical_sheat
    this.title = title
    this.updated_at = updated_at
  }
}
