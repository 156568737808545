import apiSponsor, { sponsorDisconnectToken } from '@/api/apiSponsor'
import i18n from '@/modules/i18n'
import { LOGIN_STEEP } from '@/utils/consts'

export async function logoutSponsor(commit, state, organizationId) {
  let appRedirectUrl = window.location.origin + '/login'
  let logoutToken = await sponsorDisconnectToken()

  //get current organization uuid
  let currentOrganizationUuid = state.user.logged_in_sponsors.find(
    el => el.id === organizationId
  ).organization_uuid

  return apiSponsor.noauth
    .post(`sponsor/logout`, {
      logout_token: logoutToken,
      app_redirect_url: appRedirectUrl,
      sso_value: undefined,
      organization_uuid: currentOrganizationUuid
    })
    .then(result => {
      if (!result.has_logged_sponsors) {
        commit('USER_LOGOUT')
        return Promise.resolve({ simpleLogout: true })
      } else {
        return Promise.resolve({ singleOrganizationLogout: true })
      }
    })
    .catch(e => {
      commit('SET_ERROR', {
        title: i18n.t('utils.label.error') + ' ' + e.response.data.status,
        message: e.response.data.detail
      })
      commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
      return Promise.resolve(e)
    })
}

export function sponsorListWithUniqueOrganizationParam(sponsorTab, param) {
  // Declare a new array
  let newArray = []
  // Declare an empty object
  let uniqueObject = {}
  // Loop for the array elements
  for (let i in sponsorTab) {
    // Extract the title
    let objTitle = sponsorTab[i][param]
    // Use the title as the index
    uniqueObject[objTitle] = sponsorTab[i]
  }
  // Loop to push unique object into array
  for (let i in uniqueObject) {
    newArray.push(uniqueObject[i])
  }
  return newArray
}

export function organizationsUuidList(loggedSponsorList) {
  let tab = []
  loggedSponsorList.forEach(el => tab.push(el.organization_uuid))
  return tab
}
