import { SECTION_MODE, SECTION_TYPE, STATE } from '@/utils/consts'

export default {
  section: null,
  sectionSimple: null,
  dayNumber: 0,
  current_state_day: STATE.NONE,
  current_state_slot: STATE.NONE,
  emailAttendeesError: [],
  showEmergeOnApp: false,
  attendeesToSign: [],
  currentPart: -1,
  collectiveLink: '',
  codeQR: null,
  sectionType: [SECTION_TYPE.INTER, SECTION_TYPE.INTRA],
  sectionMode: SECTION_MODE,
  isSectionEndDateEdited: false,
  isUpdateRegistrationModal: false,
  traineeInfo: {
    firstname: null,
    lastname: null
  },
  /**
   * @typedef {{
   *   traineeRegistrationId: Number - require,
   *   arrayFields: Array | null,
   * }}
   */
  filteredCustomFields: {
    traineeRegistrationId: null,
    arrayFields: null
  },
  valueCustomFields: null,

  /**
   * @typedef {{
   *     doNotEdit: {
   *       id: Number - require
   *     },
   *     editCode: {
   *       code: String - require
   *     },
   *     editName: { name: String },
   *     editType: { type: String },
   *     editPlanning: {
   *       start_at: String - require,
   *       end_at: String - require,
   *       morning_start: String,
   *       morning_end: String,
   *       afternoon_start: String,
   *       afternoon_end: String,
   *       auto_create_sequences: Boolean,
   *       timezone: String
   *     },
   *     editLocation: {
   *       mode: String,
   *       location: String,
   *       address: String,
   *       zipcode: String,
   *       city: String,
   *       country: String
   *     },
   *     editOptions: {
   *       sign_before_slot_start_delay: Number,
   *       site: Object
   *     },
   *     editTags: {
   *       tags: String
   *     }
   *
   * }} updatedSection
   */
  updatedSection: {
    doNotEdit: {
      id: null
    },
    editCode: {
      //required
      code: ''
    },
    editName: { name: '' },
    editType: { type: [] },
    editPlanning: {
      //required
      start_at: null,
      //required
      end_at: null,
      morning_start: null,
      morning_end: null,
      afternoon_start: null,
      afternoon_end: null,
      auto_create_sequences: false,
      timezone: null
    },
    editLocation: {
      mode: [],
      location: '',
      address: '',
      zipcode: '',
      city: '',
      country: ''
    },
    editOptions: {
      sign_before_slot_start_delay: null,
      site: null
    },
    editTags: {
      tags: null
    }
  },

  /**
   * @typedef {{
   *   id: Number - require,
   *   reference: String | null,
   *   meal_included: Boolean,
   *   financing_method: String | null,
   * }}
   */
  updateTraineeRegistration: {
    id: null, //required
    reference: '',
    meal_included: false,
    financing_method: ''
  }
}
