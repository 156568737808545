import api from '@/api/api'

import dayjs from 'dayjs'

import { STATUS } from '@/utils/consts'

import { timestampsSecondNow } from '@/utils/date'
import { ROLE } from '@/utils/consts'
import { sentryCustomMessage } from '@/interface/sentryInterface'

export default {
  //addExistingTrainee
  async addExistingTrainee({ commit, rootState, dispatch }, id) {
    return await api.auth
      .put(`trainees/${id}`, {})
      .then(newTrainee => {
        //assigne trainee to sessions section
        api.auth
          .post(
            `sessions/${rootState.section.section.session.id}/assign?section=${rootState.section.section.id}`,
            {
              traineeId: newTrainee.id
            }
          )
          .then(async attendances_trainee => {
            commit(
              'section/ADD_TRAINEE_TO_SECTION',
              {
                attendances_trainee
              },
              { root: true }
            )

            await dispatch('setNewTraineeId', newTrainee.id)

            return Promise.resolve(newTrainee)
          })
          .catch(e => {
            commit('utils/SET_COMMON_ERROR', e, { root: true })
            sentryCustomMessage('try add trainee with status refused')
            return Promise.reject(e)
          })
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  addNewTrainee({ commit, rootState }, { trainee }) {
    return api.auth
      .post(`trainees`, {
        lastname: trainee.lastname,
        firstname: trainee.firstname,
        email: trainee.email,
        phone: trainee.phone,
        establishment: trainee.establishment,
        address: trainee.address,
        city: trainee.city,
        country: trainee.country,
        zipcode: trainee.zipcode
      })
      .then(newTrainee => {
        //assigne trainee to sessions section
        return api.auth
          .post(
            `sessions/${rootState.section.section.session.id}/assign?section=${rootState.section.section.id}`,
            {
              traineeId: newTrainee.id
            }
          )
          .then(attendances_trainee => {
            commit(
              'section/ADD_TRAINEE_TO_SECTION',
              {
                attendances_trainee
              },
              { root: true }
            )

            return Promise.resolve(newTrainee)
          })
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  editTrainee({ commit, getters }, { trainee, traineeId }) {
    return api.auth
      .put(`trainees/${traineeId}`, {
        lastname: trainee.lastname,
        firstname: trainee.firstname,
        email: trainee.email,
        phone: trainee.phone,
        establishment: trainee.establishment,
        address: trainee.address,
        city: trainee.city,
        country: trainee.country,
        zipcode: trainee.zipcode,
        id: traineeId
      })
      .then(editTrainee => {
        commit('EDIT_TRAINEE', { editTrainee, trainees: getters.trainees })
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  //Ajout au cash de traineeToEdit
  setEditTrainee({ commit }, traineeToEdit) {
    commit('SET_EDIT_TRAINEE', traineeToEdit)
  },

  replaceTrainee(
    { commit, state, getters, rootState, rootGetters },
    { replacementId, traineeId }
  ) {
    return api.auth
      .put(`sections/${rootState.section.section.id}/replacedBy`, {
        traineeId: traineeId,
        replacementId: replacementId
      })
      .then(() => {
        const currentPart = rootGetters['section/currentPart']
        commit('SET_REPLACE_TO_TRAINEE', {
          replacedTraineeId: traineeId,
          currentPart,
          trainees: getters.trainees
        })
      })
  },
  //ajout au cash traineeToReplace
  setReplaceTrainee({ commit }, traineeToReplace) {
    commit('SET_REPLACE_TRAINEE', traineeToReplace)
  },
  //ajout au cash de l'id du newTrainee
  setNewTraineeId({ commit }, id) {
    commit('SET_NEW_TRAINEE_ID', id)
  },
  // SET ABSENCE
  absentAttendee({ commit, getters, rootGetters }, { id, role }) {
    return api.auth
      .patch(`${role}Attendances/${id}`, {
        updated_at: timestampsSecondNow(),
        status: STATUS.ABSENT.api
      })
      .then(updatedTrainee => {
        let tmpTrainee = getters.traineeAttendances.find(t =>
          t.attendances.find(t2 => t2.attendee.id === updatedTrainee.trainee_id)
        )
        commit('UPDATE_STATUS_TRAINEE', {
          status: STATUS.ABSENT,
          traineeId: tmpTrainee.id,
          trainees: getters.trainees,
          sequence: updatedTrainee.sequence
        })
        return Promise.resolve()
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  // SET PRESENCE
  presenceAttendee({ commit, getters, rootGetters }, { id, role }) {
    return api.auth
      .patch(`${role}Attendances/${id}`, {
        updated_at: timestampsSecondNow(),
        status: STATUS.PRESENT.api
      })
      .then(updatedTrainee => {
        const currentSequence = rootGetters['section/currentSequence']

        commit('UPDATE_TRAINEE', {
          updatedTrainee,
          trainees: getters.trainees,
          sequence: currentSequence
        })
        return Promise.resolve()
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  localPresenceAttendee(
    { commit, getters, rootGetters },
    { traineeAttendanceId, role }
  ) {
    let currentSequence = rootGetters['section/currentSequence']

    let tmpTrainee = getters.traineeAttendances.find(t =>
      t.attendances.find(t2 => t2.id === traineeAttendanceId)
    )

    if (role === ROLE.TRAINEE) {
      commit('UPDATE_STATUS_TRAINEE', {
        status: STATUS.PRESENT_NO_SIGNATURE,
        traineeId: tmpTrainee.id,
        trainees: getters.trainees,
        sequence: currentSequence
      })
    }
    return Promise.resolve()
  },

  // SEND COMMENT
  sendTraineeComment({ commit, rootState }, { comment, traineeId }) {
    return api.auth
      .post(`sections/${rootState.section.section.id}/comments`, {
        content: comment,
        updated_at: dayjs().unix(),
        trainee_attendance_id: traineeId,
        day: rootState.section.day
      })
      .then(() => {
        Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },

  setDaysOfSections({ commit, rootState }, daySectionIds) {
    let nbSection = daySectionIds.length
    let sections = []
    commit('RESET_SECTION_PLANNING')
    commit('SET_LOADING', true)
    daySectionIds.forEach(sectionId => {
      api.auth.get(`sections/${sectionId}`).then(section => {
        nbSection--
        sections[sectionId.toString()] = section
        if (nbSection <= 0) {
          commit('SET_SECTIONS', sections)
          commit('SET_LOADING', false)
        }
      })
      //.catch((e) => Promise.reject(e));
    })
    // return Promise.resolve(sections);
  },

  deleteTraineeRegistrations({ commit, rootState }, traineeRegistrationId) {
    return api.auth
      .delete(`trainee_registrations/${traineeRegistrationId}`)
      .then(() => {
        commit('DELETE_ATTENDANCE', {
          toDeleteAttendanceId: traineeRegistrationId,
          section: rootState.section.section
        })
        Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  deleteTraineeRegistrationsSignature(
    { commit, rootState, getters },
    attendance
  ) {
    return api.auth
      .delete(`traineeAttendances/${attendance.id}/signature`, {
        updated_at: timestampsSecondNow()
      })
      .then(() => {
        commit('UPDATE_STATUS_TRAINEE', {
          status: STATUS.PRESENT_NO_SIGNATURE,
          traineeId: attendance.attendee.id,
          trainees: getters.trainees,
          sequence: attendance.part.sequence
        })
        Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  localDeleteTraineeRegistrationsSignature(
    { commit, rootState, getters },
    attendance
  ) {
    commit('DELETE_SIGNATURE', {
      traineeId: attendance.attendee.id,
      trainees: getters.trainees,
      sequence: attendance.part.sequence
    })
  },

  async updateTraineeCustomField({}, payload) {
    return new Promise((resolve, reject) => {
      api.auth
        .put(
          `trainee-custom-field-values?trainee_id=${payload.traineeId}&custom_field_id=${payload.customFieldId}`,
          {
            value: payload.value.toString()
            /**WARNING : to remove in the futur. For the moment, api accept only Strings. So we parse the booleans to string.
             But it can create bug if new type are deployed, like object or array. */
          }
        )
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  },

  async getTraineeCustomField({}, traineeIds) {
    let traineeUrl = ''

    traineeIds.forEach(traineeId => {
      traineeUrl += 'trainee_ids[]=' + traineeIds
      if (traineeId !== traineeIds[traineeIds.length - 1]) traineeUrl += '&'
    })

    return new Promise((resolve, reject) => {
      api.auth
        .get(`trainee-custom-field-values?${traineeUrl}`)
        .then(res => {
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }
}
