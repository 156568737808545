import { TYPE_LOGIN, LOGIN_STEEP, ACTUAL_ROLE } from '@/utils/consts'
import defaultCustomStyle from '@/utils/defaultCustomStyle.json'

export default {
  /**
   * Currently only 2 types : 'Trainer' or 'Sponsor'
   * Default typeMode: 'Trainer'
   */
  typeMode: ACTUAL_ROLE.TRAINER,

  user: null,
  token: '',
  uuid: '',
  organization: {},
  logos: {},
  customStyle: defaultCustomStyle,
  /** Store error message */
  error: {
    title: '',
    message: ''
  },

  /** Organization Login data */
  login_data: {
    email: '',
    password: '',
    type: TYPE_LOGIN.EMAIL,
    organisationId: null,
    method: null,
    trainers: [],
    sponsors: {},
    methods: [],
    code: '',
    connexionMethode: null,
    ssoValue: ''
  },

  currentMicrosoftAccount: null,
  steepLogin: LOGIN_STEEP.NULL,
  connectionWithPasswordOrPin: null,
  isMicrosoftEmailInvalid: false,
  CurrentOrganizationId: null,
  sponsorOrganizationsName: null,
  trainerUser: {},
  isLastConnectedOrganization: false
}
