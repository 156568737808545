import { sortOrReverse } from '@/utils/function'

export default {
  getUserType: state => state.userType,

  getDocuments: (state, getters) => {
    if (state.sortDocumentsMethod) {
      return getters.sortedDocuments(state.sortDocumentsMethod)
    } else return state.documents
  },

  getFilter: state => {
    return state.filter
  },

  getObserverCategory: state => {
    return state.categoryObserver
  },

  getObserverPeriod: state => {
    return state.periodObserver
  },

  getTabSections: state => {
    return state.sectionTab
  },

  getTabSessions: state => {
    return state.sessionTab
  },

  getTabTrainings: state => {
    return state.trainingTab
  },

  getTabTrainers: state => {
    return state.trainerTab
  },

  getTabTrainees: state => {
    return state.traineeTab
  },

  getTabSurveys: state => {
    return state.surveyTab
  },

  sortedDocuments: state => key => sortOrReverse(state.documents, key),

  getSelectedFilter: state => state.selectedFilter,

  disableButton: state => state.disableButton
}
