import api from '@/api/api'

export default {
  getTrainerSurveyEntries({ commit, state }) {
    return api.auth
      .get(`trainer-survey-entries`)
      .then(trainerSurveyEntries => {
        commit('SET_CURRENT_TRAINER_SURVEY_ENTRIES', trainerSurveyEntries.items)
        return Promise.resolve(trainerSurveyEntries.items)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  }
}
