import DefaultLayout from '@/layouts/DefaultLayout'

import store from '@/store'
import { getRoleByLocalStorage } from '@/utils/function'
import { ACTUAL_ROLE } from '@/utils/consts'

export default [
  {
    // To be improve, TrainerSurveyPage => SurveyPage who can manage different survey type
    path: '/survey',
    name: 'TrainerSurveyPage',
    meta: {
      title: 'survey',
      layout: DefaultLayout
    },
    beforeEnter: (to, from, next) => {
      if (
        store.getters['auth/organization'].partner_settings.allowSurvey ||
        store.getters['auth/organization'].partner_settings.allowSurvey ===
          undefined
      )
        if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) next()
        else next('/NotFound404')
    },

    component: () => import('@/pages/TrainerSurveyPage')
  }
]
