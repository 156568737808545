import { STATUS } from '@/utils/consts'

export default class Attendance {
  id
  departure_earliness
  arrival_lateness
  departure_lateness
  meal_included
  registration_id
  signature_url
  signature_status
  signed_at
  status
  emailSend = 'notSend'
  part
  attendee

  constructor(
    {
      id,
      departure_earliness,
      arrival_lateness,
      departure_lateness,
      meal_included,
      registration_id,
      signature_url,
      signature_status,
      signed_at,
      status
    },
    part,
    attendee
  ) {
    this.id = id
    this.departure_earliness = departure_earliness
    this.arrival_lateness = arrival_lateness
    this.departure_lateness = departure_lateness
    this.meal_included = meal_included
    this.registration_id = registration_id
    this.signature_url = signature_url
    this.signature_status = signature_status
    this.signed_at = signed_at
    this.status = status
    this.part = part
    this.attendee = attendee
  }

  refresh({
    departure_earliness,
    arrival_lateness,
    departure_lateness,
    meal_included,
    registration_id,
    signature_url,
    signature_status,
    signed_at,
    status
  }) {
    this.departure_earliness = departure_earliness
    this.arrival_lateness = arrival_lateness
    this.departure_lateness = departure_lateness
    this.meal_included = meal_included
    this.registration_id = registration_id
    this.signature_url = signature_url
    this.signature_status = signature_status
    this.signed_at = signed_at
    this.status = status
  }

  getStatus() {
    // DEPRECIATED
    return Object.values(STATUS).find(s => s.api === this.status)
  }
}
