export default {
  toasts: [],
  loader: false,
  transitionAnimation: true,
  bodyScrollLock: false,
  is_loading_button: false,
  loadingPortalDocumentList: false,
  loadingPortalFilter: false,
  tabShowLoadingDownload: [],
  error: {
    show: false,
    title: '',
    message: ''
  },
  commonError: null,
  errorData: null
}
