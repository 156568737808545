import fr from './fr'
import en from './en'
import Vue from 'vue'
import dayjs from 'dayjs'

export const minTwoDigits = n => {
  return (n < 10 ? '0' : '') + n
}

Vue.filter('formatDateHour', function(value) {
  if (!value) return ''
  const date = dayjs(value)
  return `${minTwoDigits(date.get('hour'))}h${minTwoDigits(date.get('minute'))}`
})

export default {
  fr,
  en
}
