import { STATUS, ROLE } from '@/utils/consts'

export default {
  section: state => state.section,
  sectionSimple: state => state.sectionSimple, //only use in sectionPdf
  current_state_day: state => state.current_state_day, //manage the current section of day
  current_state_slot: state => state.current_state_slot, //manage the current slot state
  showEmergeOnApp: state => state.showEmergeOnApp,
  emailAttendeesError: state => state.emailAttendeesError,
  attendeesToSign: state => state.attendeesToSign,
  mode: state => state.mode, //current mode of emargement
  currentPart: state => state.currentPart,
  getPartBySequence: state => sequence =>
    state.section.day.findPartBySequence(sequence),
  getPartByPartId: state => id => state.section.day.parts[id],
  collectiveLink: state => state.collectiveLink,
  getCodeQR: state => state.codeQR,
  getUpdatedSection: state => state.updatedSection,
  getUpdateTraineeRegistration: state => state.updateTraineeRegistration,
  getIsUpdateRegistrationModal: state => state.isUpdateRegistrationModal,
  getSectionMode: state => state.sectionMode,
  getSectionType: state => state.sectionType,
  getTraineeInfo: state => state.traineeInfo,
  getFilteredCustomFields: state => state.filteredCustomFields,
  getValueCustomFields: state => state.valueCustomFields,
  getIsSectionEndDateEdited: state => state.isSectionEndDateEdited,
  getAttendancesByRoleAndPartAndStatus: state => (role, part, status) => {
    return state.section.getAttendancesByRoleAndPartAndStatus(
      role,
      part,
      status
    )
  },
  getAttendancesByRoleAndPartAndStatusAndStatusSignature: state => (
    role,
    part,
    status
  ) => {
    return state.section.getAttendancesByRoleAndPartAndStatusAndStatusSignature(
      role,
      part,
      status
    )
  },
  getAttendancesByRoleAndPart: state => (role, part) => {
    return state.section.getAttendancesByRoleAndPart(role, part)
  },
  // If a trainer is present, we can pass to trainee with the selected currentPart
  canPassToTrainee: (state, getters) => {
    return (
      getters.getAttendancesByRoleAndPartAndStatus(
        ROLE.TRAINER,
        state.currentSequence,
        STATUS.PRESENT.api
      ).length >= 1 &&
      getters.getAttendancesByRoleAndPartAndStatus(
        ROLE.TRAINEE,
        state.currentSequence,
        STATUS.AWAITED.api
      ).length !== 0
    )
  },
  canPassToEnd: (state, getters) => {
    return (
      getters.getAttendancesByRoleAndPartAndStatus(
        ROLE.TRAINEE,
        state.currentSequence,
        STATUS.PRESENT.api
      ).length !== 0
    )
  },
  // Verify, if section has finish
  hasFinishPart: (state, getters) => {
    return (
      getters.canPassToTrainee &&
      getters.getAttendancesByRoleAndPartAndStatus(
        ROLE.TRAINEE,
        state.currentSequence,
        STATUS.AWAITED.api
      ) === 0
    )
  },
  currentSequence: state => {
    if (state.section === null) {
      return null
    }
    return state.section.day.parts[state.currentPart].sequence
  }
}
