<template>
  <div class="LayoutDefault" id="LayoutDefault">
    <main class="LayoutDefault-main" v-body-scroll-lock="getbodyScrollLock">
      <slot />
    </main>

    <transition-group
      v-if="toasts.length > 0"
      name="toast"
      tag="div"
      class="LayoutDefault-toasts"
    >
      <BSCToast
        v-for="toast in toasts"
        :key="toast.id"
        :label="toast.label"
        :type="toast.type"
      />
    </transition-group>

    <div class="LayoutDefault-loading" v-if="loading">
      <img
        class="LayoutDefault-loading-img"
        src="@/assets/animations/loader-all.svg"
      />
    </div>

    <footer class="LayoutDefault-footer">
      <div class="LayoutDefault-footer-columns">
        <div
          class="LayoutDefault-footer-column LayoutDefault-footer-column-pointer"
        >
          <BSCTooltip position="top" :isClickable="true">
            <template v-slot:content>
              <div class="LayoutDefault-footer-column-lang">
                <div @click="changeLang('fr')">
                  {{ $t('utils.language.french') }}
                </div>
                <div @click="changeLang('en')">
                  {{ $t('utils.language.english') }}
                </div>
              </div>
            </template>
            {{ $t('utils.language.title') }}
          </BSCTooltip>
        </div>
        <div class="LayoutDefault-footer-column"></div>
      </div>
      <div class="LayoutDefault-footer-bottom">
        <span v-if="$isPartner"
          >{{ this.$appName() }} | Version {{ versionApp }}</span
        >
        <span v-else>bsoft.fr - {{ dateYear }} | Version {{ versionApp }}</span>
      </div>
    </footer>
  </div>
</template>

<script>
'use strict'

import dayjs from 'dayjs'

import { mapGetters } from 'vuex'

export default {
  name: 'DefaultLayout',
  computed: {
    ...mapGetters({
      toasts: 'utils/toasts',
      loading: 'utils/loader',
      error: 'utils/error',
      getbodyScrollLock: 'utils/getbodyScrollLock'
    }),
    dateYear() {
      return dayjs().get('year')
    },
    versionApp() {
      return process.env.VUE_APP_VERSION
    }
  },
  methods: {
    closeError() {
      this.$store.dispatch('utils/closeError')
    },
    changeLang(lang) {
      this.$i18n.locale = lang
      document.documentElement.setAttribute('lang', lang)
      localStorage.setItem('lang', lang)
    }
  },
  mounted() {
    window.zESettings = {
      webWidget: {
        helpCenter: {
          filter: {
            section: '360004680560' //zendesk id
          }
        }
      }
    }
  },
  watch: {
    error: {
      handler(newVal) {
        if (newVal.show) {
          this.$modal.show('dialog', {
            title: newVal.title,
            text: newVal.message,
            draggable: false,
            adaptive: true,
            buttons: [
              {
                title: 'ok',
                handler: () => {
                  this.$modal.hide('dialog')
                  this.$store.dispatch('utils/closeError')
                }
              }
            ]
          })
        } else {
          this.$modal.hide('errorDialog')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.LayoutDefault {
  position: relative;

  &-main {
    height: 100%;
    min-height: 100vh;
  }

  &-toasts {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
  }

  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $hover-grey;
    opacity: 0.5;

    &-img {
      width: 150px;
      height: 150px;
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    background-color: $menu-background;
    color: $white;
    padding: 1rem 0 0;

    &-bottom {
      display: flex;
      justify-content: center;
      padding: 0.5rem 0;
    }

    &-columns {
      display: flex;
      justify-content: space-around;
      padding: 0.5rem 0;
    }

    &-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media (max-width: $x-large) {
        padding: 0.5rem 0rem;
      }

      > a {
        color: $white;
      }

      &-pointer {
        cursor: pointer;
      }

      &-lang {
        color: $black;

        > div {
          cursor: pointer;
          padding: 0.5rem;
          width: 150px;

          &:hover {
            background-color: $light;
          }
        }
      }
    }
  }
}

/* Toast Animation */
.toast-item {
  display: inline-block;
  margin-right: 10px;
}

.toast-enter-active,
.toast-leave-active {
  transition: all 1s;
}

.toast-enter, .toast-leave-to /* .toast-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateY(30px);
}

#launcher {
  z-index: 150 !important;
}
</style>
