<template>
  <div class="container">
    <div class="not-found">
      <div class="not-found__image">
        <img src="@/assets/illustration/empty.png" alt="Empty" />
      </div>

      <h1 class="not-found__title">404</h1>

      <p class="not-found__sub-title">{{ $t('utils.error.notFoundTitle') }}</p>
      <p class="not-found__sub-title">{{ $t('utils.error.notFound') }}</p>

      <router-link class="not-found__back" to="/">
        <BSCButton
          :label="$t('utils.action.backSafe')"
          type="primary"
          @click.native="edit"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound404'
}
</script>

<style lang="scss">
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2rem 15rem;
  background-color: $white;
}

.not-found__image {
  width: 600px;
  height: 450px;

  img {
    width: 100%;
    height: 100%;
  }
}

.not-found__title {
  font-size: 3.4rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.not-found__sub-title {
  font-size: 1.7rem;
  margin-bottom: 3rem;
}
</style>
