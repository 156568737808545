export default {
  utils: {
    label: {
      trainer: 'Trainer',
      firstName: 'First Name',
      lastName: 'Last Name',
      organization: 'Organization: ',
      phone: 'Phone',
      establishment: 'Company',
      address: 'Address',
      city: 'City',
      country: 'Country',
      zipcode: 'Zip code',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Password Confirmation',
      caution: 'Warning',
      yes: 'Yes',
      no: 'No',
      signHere: 'Sign here',
      required: 'required',
      optional: 'optional',
      error: 'Error',
      help: 'Help',
      loading: 'Loading',
      seeMore: 'See more',
      seeLess: 'See less',
      survey: 'Survey',
      training: 'Training',
      section: 'Section',
      sectionDate: 'Date of Section'
    },
    labelInfo: {
      survey: 'Survey: ',
      trainer: 'Trainer: ',
      sessionCode: 'Session Code: ',
      section: 'Section: ',
      sectionDate: 'Date of Section: '
    },
    language: {
      title: 'Language',
      french: 'Français',
      english: 'English'
    },
    action: {
      actions: 'Actions',
      cancel: 'Cancel',
      edit: 'Edit',
      success: 'Success',
      confirm: 'Confirm',
      ok: 'Ok',
      close: 'Close',
      validate: 'Confirm',
      present: 'Present',
      next: 'Next',
      restart: 'Restart',
      replace: 'Replace',
      add: 'Add',
      send: 'Send',
      download: 'Download',
      selectAll: 'Select All',
      deselectAll: 'Deselect All',
      back: 'Back',
      backSafe: 'Back to a safe place',
      info: 'You must select at least'
    },
    error: {
      title: 'Error',
      basic: 'An error has occurred',
      notAvailable:
        'The current date does not allow you to perform this action',
      minSearchString: 'Enter 3 or more characters',
      emptyResult: 'No result',
      unknown: 'Error unknown',
      evaluationCompleted: 'The evaluation has already been completed',
      notFoundTitle: 'Page Not Found',
      notFound:
        'We are sorry the page you are looking for does not exist or no longer exists',
      temporalSigningNotAllowed: 'Signing mode does not allow dynamic QR code',
      sectionNotOngoing: 'The section must be in progress'
    },
    status: {
      present: 'Present',
      presentNoSignature: 'No signature',
      awaited: 'Expected',
      absent: 'Absent',
      replaced: 'Remplaced'
    },
    statusTraining: {
      ongoing: 'In progress',
      canceled: 'Cancel',
      scheduled: 'Coming up',
      closed: 'Locked'
    },
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December'
    },
    monthsShort: {
      january: 'Jan',
      february: 'Feb',
      march: 'Mar',
      april: 'Apr',
      may: 'May',
      june: 'Jun',
      july: 'Jul',
      august: 'Aug',
      september: 'Sep',
      october: 'Oct',
      november: 'Nov',
      december: 'Dec'
    },
    days: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday'
    },
    daysShort: {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun'
    }
  },
  commonError: {
    title: 'Error',
    notFound: 'Element not found',
    forbidden: 'Action is not permissible',
    badRequest: 'Invalid action',
    unknown: 'Unknown error'
  },
  routes: {
    title: {
      home: 'Home',
      account: 'Profile',
      calendar: 'Calendar',
      login: 'Login',
      microsoftConnect: 'Microsoft Login',
      newSection: 'Add new section',
      survey: 'Survey',
      training: 'Training in progress',
      documentPdf: 'Attendance sheet',
      daytime: 'My days',
      404: '404'
    }
  },
  errors: {
    unknown: 'Error unknown',
    needModeration: 'The modification is subject to administrative validation'
  },

  menu: {
    title: 'Menu',
    calendar: 'My calendar',
    survey: 'Surveys',
    portal: 'Documents',
    journeys: 'My planning',
    account: 'My account'
  },
  login: {
    googleSSO: 'Google Log In',
    microsoftLogin: 'Microsoft Log In',
    microsoftRedirect: 'May take few minutes',
    title: 'Log In',
    portal: {
      partner: {
        access: 'Sponsor access',
        button: 'Access the sponsor space'
      },
      trainer: {
        access: 'Trainer access',
        button: 'Access the trainer space'
      }
    },
    sponsor: {
      infoPassword:
        'Password is that of your sponsor account, identical for all your organizations'
    },
    label: {
      stayConnected: 'Remember Me',
      connect: 'Log In',
      needHelp: 'Need Help ?'
    },
    email: {
      link: 'Connect using an pin code',
      placeholder: 'Email'
    },
    password: {
      link: 'Connect using a password',
      placeholder: 'Password'
    },
    code: {
      email: 'An access code was sent to your e-mail',
      sms: 'An SMS with your access code has been sent',
      resend: 'Resend a verification code',
      error: 'The PIN code is not generated, impossible to verify it.'
    },
    help: {
      p1FirstPart:
        'If you are here, you have received an email inviting you to use',
      p1SecondPart:
        "to track your trainees' attendance. Your account is already set, just log in.",
      p2:
        'The email address on which you received this invitation will serve as a connection medium, please fill in the email field with it.',
      p3:
        'Once the field validated, an email will be sent to this address. Click to generate a secure one time access code and copy it in the application.',
      p4: 'You can then see all your trainings at a glance !'
    },
    organization: {
      title: 'In what organization would you log in ?',
      mode: 'What connection mode would you use ?',
      email: 'EMAIL',
      sms: 'SMS'
    },
    error: {
      notValidated: 'You have not validated your account.',
      noMatchingEmail: 'No account matches this email address',
      noOrganizationPassword:
        "Your organizations or accounts have not enabled 'password' login method",
      noOrganizationGoogle:
        'Your organizations or accounts have not enabled SSO Google login method',
      noOrganizationMicrosoft:
        'Your organizations or accounts have not enabled SSO Microsoft login method',
      selectAnotherMicrosoftAccount: 'Please select another microsoft account',
      titlePopupMicrosoft: 'In the process of connecting microsoft',
      messagePopupMicrosoft:
        'A microsoft login window is already open, please continue the login on the microsoft window or close the microsoft window before any other action',
      pinRequestRequired: 'You must first make a PIN code request, none found.',
      waitingDelay: 'A delay is required before sending a new request.',
      notGenerated: 'The PIN code is not generated, impossible to verify it.',
      expired: 'The PIN code has expired, please repeat the login process.',
      pinAlreadyGenerated:
        "This request can only be performed before the code's generation. The code already exists.",
      notValid: 'The code is not valid.',
      invalidCredentials: 'Incorrect email or password.',
      invalidPassword: 'Incorrect password',
      methodNotAllowed:
        'The connection is not allowed for this organization, the user does not belong to the organization, or the user does not have a password configured',
      invalidAccess: 'Access code provided is invalid',
      passwordDisabled:
        'Logging in with a password is not enabled for this account.',
      ssoError:
        'An error has occured during the SSO. If the error persists, contact your organization.',
      failDisconnectSSO: 'SSO logout failed',
      expiredToken: 'Your login token has expired. Try Again.',
      trainerNotFound: 'No trainer corresponds to this email address.',
      invalidToken: 'Your login token is invalid',
      error: 'An error occurred. Please log in again.'
    }
  },
  lock: {},
  home: {
    label: {
      yesterday: 'Yesterday',
      tomorrow: 'Tomorrow',
      previously: 'Previously',
      commingSoon: 'Coming soon',
      today: 'Today'
    }
  },
  calendar: {
    days: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesdeay: 'Wednesdeay',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday'
    },
    renderRange: 'From {weekStart} to {weekEnd}'
  },
  account: {
    title: 'My account',
    logout: {
      title: 'Logout',
      message: 'Are your sure to logout ?',
      action: 'Logout'
    },
    logoutSSORequest: {
      title: 'SSO Logout',
      message: 'Would you like to log out of SSO too?',
      action1: 'SSO log out',
      action2: 'Only application log out'
    },
    toast: {
      logout: 'Goodbye',
      logoutSingleOrganization: 'Logged out of the organization',
      loginSingleOrganization: 'Logged in to the organization'
    },
    information: {
      title: 'Personal data'
    },
    help: {
      title: 'Need help ?',
      button: 'Online help',
      organisationTitle: 'To contact your organization: '
    },
    organizationList: {
      title: 'My Connected Organizations',
      labelButton: 'Manage organizations / Logout',
      modal: {
        appLogoutText:
          'To exit the application, please log out of all organizations',
        closeWindow: 'Close window',
        connectedAs: 'You are logged in as',
        description: 'List of available organizations',
        loginAction: 'Log In',
        loginInfo: 'Connected',
        logoutAction: 'Log Out',
        logoutInfo: 'Disconnected',
        logoutTitle: 'organizations logged to'
      }
    },
    trainerOrganizationList: {
      title: 'My Connected Organizations',
      labelButton: 'Manage organizations',
      modal: {
        appLogoutText:
          'To exit the application, please log out of all organizations',
        back: 'Back to the list of organizations',
        closeWindow: 'Close window',
        connectedAs: 'You are logged in as',
        description: 'List of available organizations',
        loginAction: 'Log In',
        loginInfo: 'Connected',
        logoutAction: 'Log Out',
        logoutInfo: 'Disconnected',
        logoutTitle: 'organizations logged to',
        switchAction: 'Go to'
      }
    },
    security: {
      title: 'Security Settings',
      manage: 'Password Management',
      accessOur: 'Access our',
      privacyPolicy: 'Privacy policy',
      password: {
        add: {
          title: 'Lock screen management'
        },
        delete: {
          title: 'Disable my password',
          warning:
            'You are disabling your password. We will send you a confirmation email before confirming the change.',
          success:
            'Connect using a password has been disabled for your account.'
        },
        edit: {
          title: 'Change my password'
        },
        mixin: {
          success: 'Your password has been changed.'
        },
        input: {
          help:
            'You must choose a password that meets the criteria on the right. Please avoid too simple password to protect your account from unauthorized connections.',
          helpTitle: 'Requierments',
          helpLength: '15 characters minimum',
          helpUppercase: 'At least 1 uppercase character',
          helpLowercase: 'At least 1 lowercase character',
          helpNumber: 'At least 1 digit',
          helpMatching: 'Passwords must match',
          errorLength: 'The password is less than 15 characters.',
          errorMatch: 'Passwords do not match.'
        },
        code: {
          label: 'Validation code',
          empty: 'The code is empty.',
          resend: 'Resend the verification code',
          resendTitleDisable:
            'Wait for the end of the timer to request a code again',
          resendTitle: 'Click to resend a verification code by email',
          help:
            'Please enter the confirmation code that was sent to you by e-mail to confirm your request'
        },
        manage: {
          title: 'Password Management',
          helpHasPassword:
            'Here you can change your login password or disable it for your account.',
          helpHasNotPassword:
            'You have not set a password, you can enable it to connect more simply in the future but it will decrease the security of your account.',
          add: 'Enable the login password',
          delete: 'Disable the login password',
          edit: 'Change my login password'
        }
      }
    },
    add: {
      title: 'Add a section'
    },
    preference: {
      title: 'Host preferences',
      smsAllow: {
        title: 'Receive SMS reminders',
        helpSingular:
          'You will receive an SMS {nbDay} day before a training to remind you to download the data.',
        helpPlurial:
          'You will receive an SMS {nbDay} days before a training to remind you to download the data.'
      },
      managePdf: 'Download attendance sheets',
      emargeDoc: 'Attendance sheet',
      emargeDocEmpty: 'No timesheet currently exists'
    }
  },
  journey: {
    title: 'My planning',
    empty: 'No scheduled session.',
    label: {
      training: 'Training',
      session: 'Session',
      section: 'Part',
      day: 'Day'
    },
    selectDate: {
      all: 'All',
      today: 'Today',
      yesterday: 'Yesterday',
      tomorrow: 'Tomorrow'
    }
  },
  day: {
    gaps: {
      info: 'delay in minute',
      latenessLabel: 'Lateness :'
    },
    departureEarliness: {
      info: 'early departure in minutes',
      latenessLabel: 'Early departure :'
    },
    toast: {
      endMorning: 'Morning Locked',
      end: 'Locked',
      replace: 'Replaced',
      add: 'Added',
      edit: 'Edited',
      delete: 'Deleted',
      signe: 'Signed',
      addGaps: 'Delay added',
      departureEarliness: 'Early departure added',
      absence: 'Absence confirmed',
      send: 'Sent',
      needConfirm:
        'The changes will only be applied after the validation of the training manager',
      copy: 'Copy',
      copyError: 'Error during copying'
    },
    label: {
      signedAt: 'Signed at',
      emailSend: 'E-mail sent',
      failedEmailSend: 'Send e-mail failed',
      personSingular: 'person',
      personPurial: 'people',
      trainee: 'trainee',
      trainees: 'trainees',
      trainer: 'trainer',
      trainers: 'trainers',
      remainingsSignature: 'remaining signatures',
      remainingSignature: 'remaining signature',
      search: 'Search',
      information: 'Information',
      reference: 'Reference',
      organization: 'Organization',
      dates: 'Dates',
      duration: 'Duration',
      location: 'Location',
      at: 'at',
      hours: 'hours',
      minutes: 'minutes',
      showDetails: 'Show details',
      hideDetails: 'Hide details',
      noDetail: 'No signature',
      synchronisation: 'Synchronisation'
    },
    part: {
      morning: 'Morning',
      afternoon: 'Afternoon',
      all: 'Day'
    },
    attendee: {
      empty: 'Empty list'
    },
    tooltip: {
      sign: 'Sign the attendance',
      reSign: 'Re Sign the attendance',
      empty: 'No action possible',
      departureEarliness: 'Add an early departure',
      emergeApp: 'Register the attendance on the app',
      emergeEmail: 'Register the attendance by e-mail',
      emergeDynamicQRCode: 'Using a dynamic QR Code for signing',
      gapsTrainee: 'Add a delay',
      backEmerge: 'Return to the attendance registration',
      replace: 'Replace this trainee',
      addTrainee: 'Add a trainee',
      addTraineeFromBack: 'Import a existing trainee',
      addNewTrainee: 'Create a new trainee',
      edit: 'Edit this trainee',
      manageSurvey: 'Send a survey',
      displaySectionReport: 'See the attendance sheet',
      docs: 'Display the sign-in sheets',
      absent: 'Set absent',
      comment: 'Add a comment',
      commentModalTitle: 'Comments',
      collectiveSignature: 'Collective signature',
      makeCall: 'Do the roll call',
      disableEmerge: 'There is no trainee to register'
    },
    email: {
      report: 'Errors for the attendees:',
      errors: 'The following emails could not be sent : {emailList}',
      title: "Check trainees' presence",
      selectAll: 'Select all',
      noMail: 'This trainee has no email'
    },
    emerge: {
      quit: 'Are you sure you want to leave the attendance registration ?',
      confirmAbsent: 'Confirm the absence',
      replace: 'Replace',
      edit: 'Edit',
      addTrainee: 'Add trainee'
    },
    error: {
      missingPart: 'The part has been canceled or no longer exists.',
      accessDenied: 'You do not have access to this part.',
      tryReconnect: 'If the problem persists, you can try to reconnect.'
    },
    button: {
      end: 'Finish',
      start: 'Start',
      finish: 'Lock'
    },
    replace: {
      title: 'Replace {firstName} {lastName} with:'
    },
    add: {
      title: 'Add trainee'
    },
    edit: {
      title: 'Edit {firstName} {lastName}:'
    },
    comment: {
      about: 'Your comment about {firstName} {lastName}:',
      info:
        'Your organization will see your comment. Use it to transmit additional informations',
      emptyComment: 'Please enter a comment'
    },
    template: {
      emptyLastName: 'Last name is missing',
      emptyFirstName: 'First name is missing',
      formatPhone: 'Wrong phone number format'
    },
    survey: {
      title: 'My surveys',
      error: 'Errors occured for these trainees',
      choice: 'Select survey',
      choice_send: 'Select survey to send',
      choice_eval_send: 'Select evaluation',
      individual: 'Select survey participants',
      collective: 'Collective link',
      mode: 'Select the sending method',
      resEmpty: 'No result survey found',
      response: 'See responses',
      surveySend: 'Send survey',
      evalSend: 'test a trainee',

      empty: 'No survey found',
      invalidTraineeEmail: 'This trainee has no valid email',
      labelIndividual: 'Share by email - Individual link',
      labelCollective: 'Share via QRCode - Collective link'
    },
    report: {
      title: 'Session Report',
      detail: "See session's detail",
      detailTitle: "See session's detail",
      detailSign: 'Restart signing',
      noAbsence: 'No absent',
      absentSingular: 'absent',
      absentPlural: 'absents',
      errorAbsence:
        'The number of absent trainees is abnormally high, you need to comment on the session report to explain the situation.',
      addComment: 'Add a comment',
      editComment: 'Edit my comment',
      certificate:
        'By sending the rapport, I certify that the signing data is correct'
    },
    collectiveSign: {
      createTeamsChannel: 'Add Teams signature tab',
      title: 'Collective signature',
      subtitle: "Check trainees' presence",
      subtitleHelp: "The other will be marked absent and won't be able to sign",
      linkTitle: 'Collective ',
      confidential: 'Confidential mode',
      codeRegistration: 'Registration code',
      help:
        'With confidential, fashion students must enter their full name, email or the registration code before signing.',
      error: 'Error sends the collective link'
    },
    DynamicQRCode: {
      title: 'Dynamic QR Code',
      textInfo:
        'Your Participants must scan the QR code from their access Bienvenue Formation'
    }
  },
  newSection: {
    AddTrainingModalTitle: 'Add a training',
    AddSessionModalTitle: 'Add a session',
    AddSectionModalTitle: 'Add a part',
    AddTraineesModalTitle: 'Add trainees',
    title: 'Add section',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    goToSection: 'Access to the new part',
    search: 'Search',
    objectTitle: 'Title',
    objectDay: 'Day of training',
    objectStartAt: 'Start date',
    objectEndAt: 'End date',
    objectStartHour: 'Start time',
    objectEndHour: 'End time',
    objectCode: 'Code',
    objectDureHour: 'Duration Hours',
    objectDureMin: 'Duration Minutes',
    objectLocation: 'Location',
    objectAddress: 'Address',
    objectZipcode: 'Zip code',
    objectCity: 'City',
    objectCountry: 'Country',
    emptyListTraining: 'No training',
    emptyListSession: 'No session',
    selectTraining: 'Select a training',
    selectSession: 'Select a session',
    selectSection: 'Part',
    addSection: 'Add a part',
    addTrainees: 'Add trainees',
    nbPage: 'Page {page} of {pageMax}',
    traineeAssignedText: 'Selected trainees : ',
    goCurrentSection: 'Go to the part',
    currentTraining: 'Current training : {trainingName}',
    currentSession: 'Current session : {sessionName}',
    error: {
      sessionDate: 'Start and end dates are not consistent',
      sessionSameDate: 'The start and end dates are the same'
    },
    NotRequired: 'Leave blank if unknown'
  },
  todo: {
    todoSoon: 'Soon available'
  },
  trainerSurveyEntries: {
    actifs: 'Active',
    expired: 'Completed/Expired',
    hasExpire: 'Expired on : ',
    expireTo: 'Expire on : ',
    completed: 'Completed'
  },
  portal: {
    title: 'My documents',
    emptyDocumentList: 'Your search did not match any documents',
    filter: {
      search: 'Search for the document name',
      panelTitle: 'Filter by',
      confirmButton: 'Apply filters',
      resetFilter: 'Clear all filters',
      emptyFilterList: 'No match',
      titleDisplayFilters: 'Display filters',
      titleHideFilters: 'Hide filters',
      button: {
        category: 'Category',
        selectedPeriod: 'Period',
        formation: 'Training',
        session: 'Session',
        part: 'Section',
        trainer: 'Trainer',
        trainee: 'Trainee',
        survey: 'Survey',
        more: 'More filters',
        less: 'Less filter'
      },
      category: {
        free: 'Free',
        groupCompactSheet: 'Group compact sheets',
        PackAttendanceSheet: 'Pack of attendance sheets',
        PackCompactSheet: 'Pack of compact sheets',
        PackOfAchievementCertificate: 'Pack of achievement certificate',
        SignedDocument: 'Signed document',
        PackSignedDocument: 'Pack of signed document',
        SurveyConsolidationSheet: 'Survey consolidation sheet',
        PackSurveyPerAttendeePdf: 'Pack of survey per attendee pdf',
        SurveyCsvResults: 'Survey csv results'
      }
    },
    list: {
      header: {
        documentName: 'Document name',
        category: 'Category',
        fileType: 'File type',
        documentPartner: 'Organization'
      }
    }
  },
  updateSection: {
    title: 'Edit section',
    planningTitle: 'Planning',
    sectionName: 'Name of section',
    sectionCode: 'Code',
    sectionType: 'Type',
    sectionStartDate: 'Start at date',
    sectionEndDate: 'End at date',
    sectionStartHour: 'Start at hour',
    sectionEndHour: 'End at hour',
    sectionMorning: {
      startHour: 'Morning start time',
      endHour: 'Morning end time'
    },
    sectionAfternoon: {
      startAfternoon: 'Afternoon start time',
      endAfternoon: 'Afternoon end time'
    },
    sectionTimezone: 'Timezone',
    sectionAutoCreateSequences: 'Automatically divide the game into half-days',
    sectionLocation: 'Location',
    sectionAddress: 'Address',
    sectionZipcode: 'Zipcode',
    sectionCity: 'City',
    sectionCountry: 'Country',
    sectionMode: 'Mode',
    sectionSignBeforeSlotStarDelay:
      'Authorize the start of autonomous signing-in before the start of the slot (in minutes)',
    sectionSite: 'Site',
    mode: {
      on_site: 'On site',
      remote: 'Remote',
      hybrid: 'Hybrid'
    },
    sectionTags: 'Tags',
    addTag: 'Search a tag',
    addSite: 'Search a site',
    emptyTag: 'No tag is assigned to the section',
    emptySite: 'No site is assigned to the section'
  },

  updateRegistration: {
    title: 'Update registration',
    reference: 'Registration number',
    financingMethod: 'Financing Method',
    mealIncluded: 'Meal included',
    customFieldsTitle: 'Custom fields'
  }
}
