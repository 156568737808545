import api from '@/api/api'

import i18n from '@/modules/i18n'
import { getRoleByLocalStorage } from '@/utils/function'
import apiSponsor from '@/api/apiSponsor'
import { ACTUAL_ROLE } from '@/utils/consts'

let paginedRepports = []

export default {
  editUser({ commit }, { phone, currentUser }) {
    return api.auth
      .put(`trainers/${currentUser.id}`, {
        phone: phone || currentUser.phone || '',
        has_enabled_alerts: currentUser.has_enabled_alerts || false,
        title: currentUser.title || '',
        firstname: currentUser.firstname || '',
        lastname: currentUser.lastname || ''
      })
      .then(user => {
        commit('auth/USER_REFRESH', { trainer: user }, { root: true })
        return Promise.resolve(user)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  editHasEnabledAlertsUser({ commit }, { has_enabled_alerts, currentUser }) {
    return api.auth
      .put(`trainers/${currentUser.id}`, {
        phone: currentUser.phone || '',
        has_enabled_alerts: has_enabled_alerts,
        title: currentUser.title || '',
        firstname: currentUser.firstname || '',
        lastname: currentUser.lastname || ''
      })
      .then(user => {
        commit('auth/USER_REFRESH', { trainer: user }, { root: true })
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  sendCodePassword({ rootState, dispatch, commit }, { password, type }) {
    let user = rootState.auth.user
    getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER
      ? (user = rootState.auth.user)
      : (user = rootState.auth.user.sponsor_user)
    switch (getRoleByLocalStorage()) {
      case ACTUAL_ROLE.TRAINER:
        return api.noauth
          .post('login/pin_request', {
            email: user.email,
            device_uid: rootState.auth.uuid,
            device_name: `${navigator.appCodeName}`,
            organization_id: user.organization.id,
            type: type,
            send_method: 'email'
          })
          .then(() => {
            commit('SET_PASSWORD_TYPE', { password, type })
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              dispatch(
                'utils/setError',
                {
                  title: i18n.t('utils.label.error'),
                  message: i18n.t('login.error.notValidated')
                },
                { root: true }
              )
            } else {
              dispatch(
                'utils/setError',
                {
                  title: i18n.t('utils.label.error'),
                  message: i18n.t('login.error.error')
                },
                { root: true }
              )
            }
            return Promise.reject(error)
          })
      case ACTUAL_ROLE.SPONSOR:
        return apiSponsor.noauth
          .post('sponsor/login/pin-request', {
            email: user.email,
            device_uid: rootState.auth.uuid,
            device_name: `${navigator.appCodeName}`,
            type: type,
            send_method: 'email'
          })
          .then(() => {
            commit('SET_PASSWORD_TYPE', { password, type })
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              dispatch(
                'utils/setError',
                {
                  title: i18n.t('utils.label.error'),
                  message: i18n.t('login.error.notValidated')
                },
                { root: true }
              )
            } else {
              dispatch(
                'utils/setError',
                {
                  title: i18n.t('utils.label.error'),
                  message: i18n.t('login.error.error')
                },
                { root: true }
              )
            }
            return Promise.reject(error)
          })
    }
  },
  resendPin({ rootState }, type) {
    const user = rootState.auth.user
    switch (getRoleByLocalStorage()) {
      case ACTUAL_ROLE.TRAINER:
        return api.noauth.post('login/pin_resend', {
          email: user.email,
          device_uid: rootState.auth.uuid,
          organization_id: user.organization.id,
          type,
          send_method: 'email'
        })
      case ACTUAL_ROLE.SPONSOR:
        return apiSponsor.noauth
          .post('sponsor/login/pin-resend', {
            email: user.sponsor_user.email,
            device_uid: rootState.auth.uuid,
            type,
            send_method: 'email'
          })
          .then(result => {
            return Promise.resolve(result)
          })
    }
  },
  setPassword({ state, rootState, dispatch, commit }, code) {
    const user = rootState.auth.user
    switch (getRoleByLocalStorage()) {
      case ACTUAL_ROLE.TRAINER:
        return api.auth
          .put(`trainers/${user.id}/password`, {
            new_password: state.password,
            device_uid: rootState.auth.uuid,
            code: parseInt(code)
          })
          .then(() => {
            commit('auth/SET_USER_HAS_PASSWORD', true, { root: true })
          })
          .catch(error => {
            dispatch(
              'utils/setError',
              {
                title: i18n.t('utils.label.error'),
                message: i18n.t('utils.error.basic')
              },
              { root: true }
            )
            return Promise.reject(error)
          })
      case ACTUAL_ROLE.SPONSOR:
        return apiSponsor.authSponsor
          .put(`sponsor/account/password`, {
            new_password: state.password,
            device_uid: rootState.auth.uuid,
            code: parseInt(code)
          })
          .then(() => {
            commit('auth/SET_SPONSOR_USER_HAS_PASSWORD', true, { root: true })
          })
          .catch(error => {
            dispatch(
              'utils/setError',
              {
                title: i18n.t('utils.label.error'),
                message: i18n.t('utils.error.basic')
              },
              { root: true }
            )
            return Promise.reject(error)
          })
    }
  },

  deletePassword({ rootState, dispatch, commit }, code) {
    const id = rootState.auth.user.id
    switch (getRoleByLocalStorage()) {
      case ACTUAL_ROLE.TRAINER:
        return api.auth
          .delete(`trainers/${id}/password`, {
            code: parseInt(code),
            device_uid: rootState.auth.uuid
          })
          .then(() => {
            commit('auth/SET_USER_HAS_PASSWORD', false, { root: true })
          })
          .catch(error => {
            dispatch(
              'utils/setError',
              {
                title: i18n.t('utils.label.error'),
                message: i18n.t('utils.error.basic')
              },
              { root: true }
            )
            return Promise.reject(error)
          })
      case ACTUAL_ROLE.SPONSOR:
        return apiSponsor.authSponsor
          .delete(`sponsor/account/password`, {
            code: parseInt(code),
            device_uid: rootState.auth.uuid
          })
          .then(() => {
            commit('auth/SET_USER_HAS_PASSWORD', false, { root: true })
          })
          .catch(error => {
            dispatch(
              'utils/setError',
              {
                title: i18n.t('utils.label.error'),
                message: i18n.t('utils.error.basic')
              },
              { root: true }
            )
            return Promise.reject(error)
          })
    }
  },
  getAllSectionReports({ commit, dispatch }, { user, page = 1, limit = 100 }) {
    const url = `trainers/${user.id}/closed-sections?page=${page}&limit=${limit}`

    return api.auth
      .get(url)
      .then(({ items, next, total }) => {
        paginedRepports = [...paginedRepports, ...items]

        if (next !== null)
          dispatch('getAllSectionReports', {
            user: user,
            page: page + 1,
            limit: limit
          })
        else {
          commit('SET_CLOSED_SESSIONS', { paginedRepports, next, total })

          paginedRepports = []
          return Promise.resolve()
        }
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  getPdfFromSectionId({ commit }, sectionId) {
    return api.auth
      .get(`sections/${sectionId}/pdf`, {
        base64: true
      })
      .then(data => {
        return data
      })
      .catch(error => Promise.reject(error))
  }
}
