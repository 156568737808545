import { CONNEXION_METHODE } from '@/utils/consts'

export default {
  user: state => state.user,
  isUserConnected: state => !!state.user,
  organization: state => state.organization,
  customStyle: state => state.customStyle,
  login_data: state => state.login_data,
  error: state => state.error,
  steepLogin: state => state.steepLogin,
  getConnectionWithPasswordOrPin: state => state.connectionWithPasswordOrPin,
  getIsMicrosoftEmailInvalid: state => state.isMicrosoftEmailInvalid,
  getTypeMode: state => state.typeMode,
  connexionMethode: state =>
    state.login_data.connexionMethode ||
    sessionStorage.getItem('connexionMethode') ||
    localStorage.getItem('connexionMethode') ||
    CONNEXION_METHODE.UNKNOWN,
  logos: state => state.logos,
  getCurrentMicrosoftAccount: state => state.currentMicrosoftAccount,
  getSponsorOrganizationsName: state => state.sponsorOrganizationsName,
  getTrainerUser: state => state.trainerUser,
  getIsLastConnectedOrganization: state => state.isLastConnectedOrganization
}
