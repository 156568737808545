export default {
  userType: 'trainer',

  //all documents
  documents: [],

  //final object sent with all selected filters
  filter: {
    category: [],
    period: {},
    training: [],
    session: [],
    section: [],
    trainer: [],
    trainee: [],
    survey: []
  },

  //array populated by the answers of a filter
  categoryObserver: false,
  periodObserver: false,
  sectionTab: [],
  sessionTab: [],
  trainingTab: [],
  trainerTab: [],
  traineeTab: [],
  surveyTab: [],

  sortDocumentsMethod: undefined,

  disableButton: false,

  selectedFilter: []
}
