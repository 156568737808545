import DefaultLayout from '@/layouts/DefaultLayout'
import { getRoleByLocalStorage } from '@/utils/function'
import { ACTUAL_ROLE } from '@/utils/consts'

export default [
  {
    path: '/training/:training/:session/:section/:day',
    name: 'training',
    meta: {
      title: 'training',
      layout: DefaultLayout
    },
    beforeEnter: (to, from, next) => {
      if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) next()
      else next('/NotFound404')
    },
    component: () => import('@/pages/SessionOverviewPage')
  },
  {
    path: '/doc/:docId',
    name: 'document',
    meta: {
      title: 'documentPdf',
      layout: DefaultLayout
    },
    beforeEnter: (to, from, next) => {
      if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) next()
      else next('/NotFound404')
    },
    component: () => import('@/pages/DocumentPdfPage')
  },
  {
    path: '/daytime',
    name: 'TrainingDay',
    meta: {
      title: 'daytime',
      layout: DefaultLayout
    },
    beforeEnter: (to, from, next) => {
      if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) next()
      else next('/NotFound404')
    },
    component: () => import('@/pages/TrainingDayPage')
  }
]
