import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import account from '@/store/modules/account/index.js'
import attendee from '@/store/modules/attendee/index.js'
import auth from '@/store/modules/auth/index.js'
import day from '@/store/modules/day/index.js'
import doc from '@/store/modules/doc/index.js'
import getBackEnd from '@/store/modules/getBackEnd/index.js'
import modalManagement from '@/store/modules/modalManagement/index.js'
import portal from '@/store/modules/portal/index.js'
import section from '@/store/modules/section/index.js'
import survey from '@/store/modules/survey/index.js'
import trainerSurveyEntries from '@/store/modules/trainerSurveyEntries/index.js'
import training from '@/store/modules/training/index.js'
import utils from '@/store/modules/utils/index.js'

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    attendee,
    auth,
    day,
    doc,
    getBackEnd,
    modalManagement,
    portal,
    section,
    survey,
    trainerSurveyEntries,
    training,
    utils
  }
})

export default store
