import Training from '@/model/Training'

export default class Session {
  id
  remote_id
  entity_name
  city
  start_at
  end_at
  training

  constructor({ id, code, city, start_at, end_at, training, entity_name }) {
    this.id = id
    this.remote_id = code
    this.city = city
    this.start_at = start_at
    this.end_at = end_at
    this.training = new Training(training)
    this.entity_name = entity_name
  }

  refresh({ id, code, city, start_at, end_at, training, entity_name }) {
    this.remote_id = code
    this.city = city
    this.start_at = start_at
    this.end_at = end_at
    this.training.refresh(training)
    this.entity_name = entity_name
  }
}
