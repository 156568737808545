export default {
  replaceTrainee: {
    show: false,
    traineeToReplace: ''
  },
  editTrainee: {
    show: false,
    traineeToEdit: {}
  },
  showAddTrainee: false,
  newTraineeId: 0,
  fullSectionsPlanning: [],
  loading: true
}
