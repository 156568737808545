import store from '@/store'

export function initWhiteLabel(confDir = null) {
  if (process.env.VUE_APP_PARTNER) {
    let file

    try {
      file = require(`@/assets/shared/organization/${
        confDir ? confDir : process.env.VUE_APP_SHARED_DIRECTORY
      }/conf.json`)

      store.commit('auth/SET_CUSTOM_STYLE', file)
    } catch {}
  }
}
