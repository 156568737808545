// import HomePage from '@/pages/HomePage.vue'

import NotFound404 from '@/pages/NotFound404'

import loginRoutes from '@/router/routes/loginRoutes'
import accountRoutes from '@/router/routes/accountRoutes'
import calendarRoutes from '@/router/routes/calendarRoutes'
import trainingRoutes from '@/router/routes/trainingRoutes'
import surveyRoutes from '@/router/routes/surveyRoutes'
import portalRoutes from '@/router/routes/portalRoutes'
import sectionRoutes from '@/router/routes/sectionRoutes'

import DefaultLayout from '@/layouts/DefaultLayout'
import { getRoleByLocalStorage } from '@/utils/function'
import { ACTUAL_ROLE } from '@/utils/consts'

const routes = [
  ...loginRoutes,
  ...accountRoutes,
  ...calendarRoutes,
  ...trainingRoutes,
  ...surveyRoutes,
  ...portalRoutes,
  ...sectionRoutes,

  {
    path: '/',
    redirect: { name: 'IndexPage', layout: DefaultLayout },
    name: 'bienvenue-formation',
    component: () => import('@/pages/RoutePage'),
    children: []
  },
  {
    path: '',
    name: 'IndexPage',
    meta: {
      title: 'home',
      layout: DefaultLayout
    },
    beforeEnter: (to, from, next) => {
      if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) next()
      else next('/documents')
    },
    component: () => import('@/pages/HomePage')
  },

  {
    path: '/:catchAll(.*)',
    name: 'NotFound404',
    component: NotFound404,
    meta: {
      title: '404'
    }
  }
]

export default routes
