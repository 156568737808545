'use strict'
import HttpClient from './httpClient'

import CryptoJS from 'crypto-js'

import { timestampsSecondNow } from '@/utils/date'
import Vue from 'vue'

const BASE_URL = `${process.env.VUE_APP_URL_BACKEND}/api/`
const BASE_URL_BACK = `${process.env.VUE_APP_URL_BACKEND}/`

export default {
  noApi: {
    get: (url, params) => {
      return new HttpClient()
        .get(`${BASE_URL_BACK}${url}`, {
          params
        })
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
  },
  noauth: {
    get: (url, params) => {
      return new HttpClient()
        .get(`${BASE_URL}${url}`, {
          params
        })
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    post: (url, params) => {
      return new HttpClient()
        .post(`${BASE_URL}${url}`, params)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    put: (url, params) => {
      return new HttpClient()
        .put(`${BASE_URL}${url}`, params)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    delete: (url, params) => {
      return new HttpClient()
        .delete(`${BASE_URL}${url}`, params)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
  },
  authSponsor: {
    get: (url, params, headers = {}) => {
      return sponsorValidMiddleToken()
        .then(token => {
          return new HttpClient()
            .get(`${BASE_URL}${url}`, {
              params: params,
              headers: Object.assign(
                { Authorization: 'Bearer ' + `${token}` },
                headers
              )
            })
            .then(({ data }) => {
              return Promise.resolve(data)
            })
            .catch(error => {
              return Promise.reject(error)
            })
        })
        .catch(error => {
          Vue.$log.fatal(error)

          return Promise.reject(error)
        })
    },
    post: (url, params) => {
      return sponsorValidMiddleToken()
        .then(token => {
          return new HttpClient()
            .post(`${BASE_URL}${url}`, params, {
              headers: { Authorization: 'Bearer ' + `${token}` }
            })
            .then(({ data }) => {
              return Promise.resolve(data)
            })
            .catch(error => {
              return Promise.reject(error)
            })
        })
        .catch(error => {
          Vue.$log.fatal(error)

          return Promise.reject(error.response)
        })
    },
    putSign: (url, signature) => {
      return sponsorValidMiddleToken()
        .then(token => {
          return new HttpClient()
            .put(`${BASE_URL}${url}`, signature, {
              headers: {
                Authorization: 'Bearer ' + `${token}`,
                'app-signed-at': timestampsSecondNow(),
                'app-image-format': 'data:image/png;base64'
              }
            })
            .then(({ data }) => {
              return Promise.resolve(data)
            })
            .catch(error => {
              Vue.$log.fatal(error)

              return Promise.reject(error)
            })
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    put: (url, params) => {
      return sponsorValidMiddleToken()
        .then(token => {
          return new HttpClient()
            .put(`${BASE_URL}${url}`, params, {
              headers: { Authorization: 'Bearer ' + `${token}` }
            })
            .then(({ data }) => {
              return Promise.resolve(data)
            })
            .catch(error => {
              Vue.$log.fatal(error)

              return Promise.reject(error)
            })
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    patch: (url, params) => {
      return sponsorValidMiddleToken()
        .then(token => {
          return new HttpClient()
            .patch(`${BASE_URL}${url}`, params, {
              headers: { Authorization: 'Bearer ' + `${token}` }
            })
            .then(({ data }) => {
              return Promise.resolve(data)
            })
            .catch(error => {
              return Promise.reject(error)
            })
        })
        .catch(error => {
          Vue.$log.fatal(error)
          return Promise.reject(error)
        })
    },
    delete: (url, params) => {
      return sponsorValidMiddleToken()
        .then(token => {
          return new HttpClient()
            .delete(`${BASE_URL}${url}`, {
              params: params,
              data: params,
              headers: { Authorization: 'Bearer ' + `${token}` }
            })
            .then(({ data }) => {
              return Promise.resolve(data)
            })
            .catch(error => {
              return Promise.reject(error)
            })
        })
        .catch(error => {
          Vue.$log.fatal(error)
          return Promise.reject(error)
        })
    }
  }
}

/**
 * @returns {Promise<never>|string} - base64_encode(hashedApiKey.sponsorUuiD.deviceUuid.timestamp.nonce)
 */
function sponsorValidMiddleToken() {
  const timestampSecond = timestampsSecondNow().toString()
  const nonce = Math.floor(Math.random() * 10000000).toString()
  const appToken = sessionStorage.getItem('tokenSession')
  const hashedToken = CryptoJS.SHA256(
    appToken + '.' + timestampSecond + '.' + nonce
  )

  /**
   * @type {string} - hashedApiKey = base64_encode(sha256(appToken.timestamp.nonce)
   */
  const hashedApiKey = CryptoJS.enc.Base64.stringify(hashedToken)
  const sponsorUuiD = sessionStorage.getItem('sponsorUuidSession')
  const uuid = sessionStorage.getItem('uuidSession')

  if (uuid === null || sponsorUuiD === null) {
    return Promise.reject()
  }

  return Promise.resolve(
    btoa(`${hashedApiKey}.${sponsorUuiD}.${uuid}.${timestampSecond}.${nonce}`)
  )
}

/**
 * Token can only be used once
 * @returns {Promise<never>|string} - base64_encode(hashedApiKey.trainerId.deviceUid.timestamp.nonce)
 */
export const sponsorDisconnectToken = () => {
  const appToken = sessionStorage.getItem('tokenSession')
  const sponsorUuid = sessionStorage.getItem('sponsorUuidSession')
  const uuid = sessionStorage.getItem('uuidSession')
  const timestampSecond = timestampsSecondNow().toString()
  const nonce = Math.floor(Math.random() * 10000000).toString()

  if (uuid === null || sponsorUuid === null) {
    return Promise.reject()
  }

  const hashedToken = CryptoJS.SHA256(
    appToken + '.' + timestampSecond + '.' + nonce
  )

  /**
   * @type {string} - hashedApiKey = base64_encode(sha256(appToken.timestamp.nonce)
   */
  const hashedApiKey = CryptoJS.enc.Base64.stringify(hashedToken)

  return btoa(
    `${hashedApiKey}.${sponsorUuid}.${uuid}.${timestampSecond}.${nonce}`
  )
}
