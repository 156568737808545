import api, { disconnectToken } from '@/api/api'
import i18n from '@/modules/i18n'
import { LOGIN_STEEP } from '@/utils/consts'

export async function logoutTrainer(
  commit,
  state,
  isConnexionMethodSSO,
  currentOrganization
) {
  let methodSSO = isConnexionMethodSSO
  if (methodSSO.connexionSSO) {
    let appRedirectUrl = window.location.origin + '/login'

    let trainerUser = JSON.parse(localStorage.getItem('trainerUser'))

    let ssoValue = trainerUser[state.user.email].filter(
      el => el.organizationId === currentOrganization.organizationId
    )[0].ssoValue

    let trainerId = trainerUser[state.user.email].filter(
      el => el.organizationId === currentOrganization.organizationId
    )[0].trainerId

    let organizationToken = trainerUser[state.user.email].filter(
      el => el.organizationId === currentOrganization.organizationId
    )[0].organizationToken

    let logoutToken = await disconnectToken(trainerId, organizationToken)

    return api.noauth
      .post(`trainer/logout`, {
        logout_token: logoutToken,
        app_redirect_url: appRedirectUrl,
        sso_value: ssoValue
      })
      .then(result => {
        if (methodSSO.isConnexionMicrosoft || methodSSO.isConnexionGoogle) {
          return Promise.resolve(methodSSO)
        } else {
          return Promise.resolve(result)
        }
      })
      .catch(e => {
        commit('SET_ERROR', {
          title: i18n.t('utils.label.error') + ' ' + e.response.data.status,
          message: e.response.data.detail
        })
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
        return Promise.reject(e)
      })
  }
}
