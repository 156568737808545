import { STATUS } from '@/utils/consts'
import Vue from 'vue'

export default {
  // ADD TRAINEE
  SHOW_MODAL_ADD_TRAINEE(state, flag) {
    state.showAddTrainee = flag
  },
  SET_NEW_TRAINEE_ID(state, id) {
    state.newTraineeId = id
  },
  // EDIT TRAINEE
  SHOW_MODAL_EDIT_TRAINEE(state, flag) {
    state.editTrainee.show = flag
  },
  EDIT_TRAINEE(state, { editTrainee, trainees }) {
    const trainee = trainees.find(t => t.id === editTrainee.id)
    trainee.email = editTrainee.email
    trainee.firstname = editTrainee.firstname
    trainee.lastname = editTrainee.lastname
    trainee.phone = editTrainee.phone
    trainee.establishment = editTrainee.establishment
    trainee.address = editTrainee.address
    trainee.city = editTrainee.city
    trainee.country = editTrainee.country
    trainee.zipcode = editTrainee.zipcode
  },
  SET_EDIT_TRAINEE(state, traineeToEdit) {
    state.editTrainee = {
      show: true,
      traineeToEdit
    }
  },

  SET_REPLACE_TRAINEE(state, traineeToReplace) {
    state.replaceTrainee = {
      show: true,
      traineeToReplace
    }
    state.newTraineeId = 0
  },
  SET_REPLACE_TO_TRAINEE(_, { replacedTraineeId, trainees }) {
    const replacedTrainee = trainees.find(t => t.id === replacedTraineeId)

    replacedTrainee.changeAllStatus(STATUS.REPLACED.api)
  },
  DELETE_ATTENDANCE({ state, rootState }, { toDeleteAttendanceId, section }) {
    section.attendees.forEach(at => {
      at.attendances = at.attendances.filter(
        t => t.registration_id !== toDeleteAttendanceId
      )
    })
    section.attendees = section.attendees.filter(t => t.attendances.length > 0)
  },
  UPDATE_TRAINEE(state, { updatedTrainee, trainees, sequence }) {
    let trainee = trainees.find(t => t.id === updatedTrainee.trainee_id)
    let attendance = trainee.getAttendanceByPart(sequence)

    trainee.updated_at = updatedTrainee.updated_at
    attendance.meal_included = updatedTrainee.meal_included
    attendance.signed_at = updatedTrainee.signed_at
    attendance.signature_url = updatedTrainee.signature_url
    attendance.signature_status = updatedTrainee.signature_status
    attendance.status = updatedTrainee.status
    attendance.arrival_lateness = updatedTrainee.arrival_lateness
    attendance.departure_earliness = updatedTrainee.departure_earliness
  },
  UPDATE_STATUS_TRAINEE(state, { status, traineeId, trainees, sequence }) {
    let trainee = trainees.find(t => t.id === traineeId)
    let attendance = trainee.getAttendanceByPart(sequence)

    attendance.status = status.api
    attendance.signature_status = status.apiStatusSignature
    if (
      attendance.signature_status ===
        STATUS.PRESENT_NO_SIGNATURE.apiStatusSignature ||
      attendance.signature_status === STATUS.ABSENT.apiStatusSignature
    ) {
      attendance.signature_url = null
      attendance.signed_at = null
    }
  },
  DELETE_SIGNATURE(state, { traineeId, trainees, sequence }) {
    let trainee = trainees.find(t => t.id === traineeId)
    if (trainee !== undefined) {
      let attendance = trainee.getAttendanceByPart(sequence)
      attendance.signed_at = null
      attendance.signature_url = null
    } else {
      Vue.$log.fatal('trainee undefined')
    }
  },
  SET_SECTIONS(state, newSections) {
    state.fullSectionsPlanning = newSections
  },
  RESET_SECTION_PLANNING(state) {
    state.fullSectionsPlanning = []
  },
  SET_LOADING(state, newState) {
    state.loading = newState
  }
}
