import DefaultLayout from '@/layouts/DefaultLayout'
import { getRoleByLocalStorage } from '@/utils/function'
import { ACTUAL_ROLE } from '@/utils/consts'

export default [
  {
    path: '/calendar',
    name: 'CalendarPage',
    meta: {
      title: 'calendar',
      layout: DefaultLayout
    },
    beforeEnter: (to, from, next) => {
      if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) next()
      else next('/NotFound404')
    },

    component: () => import('@/pages/CalendarPage')
  }
]
