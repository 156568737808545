export default {
  SET_MAX_TRAINEE(state, maxTrainee) {
    state.maxTrainee = maxTrainee
  },
  SET_TRAININGS(state, trainings) {
    state.trainings = trainings
  },
  SET_MAX_TRAININGS(state, maxTrainings) {
    state.maxTrainings = maxTrainings
  },
  SET_SESSIONS(state, trainings) {
    state.sessions = trainings
  },
  SET_TRAINEE(state, trainees) {
    state.trainees = trainees
  },
  SET_MAX_SESSIONS(state, maxTrainings) {
    state.maxSessions = maxTrainings
  }
}
