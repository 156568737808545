import DefaultLayout from '@/layouts/DefaultLayout'

export default [
  {
    path: '/documents',
    name: 'PortalPage',
    meta: {
      title: 'portal',
      layout: DefaultLayout
    },
    component: () => import('@/pages/PortalPage')
  }
]
