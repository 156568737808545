<template>
  <div id="app">
    <component :is="$route.meta.layout || 'div'">
      <router-view v-slot="{ Component }">
        <transition name="fade-in" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </component>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    // Setting Language in the HTML document
    document.documentElement.setAttribute('lang', this.$i18n.locale)
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
}

html,
body {
  margin: 0;
  min-height: 100vh;
}

#app {
  color: #2c3e50;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
