export default {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents
  },

  SET_FILTER(state, filter) {
    state.filter[Object.keys(filter)] = Object.values(filter)
  },

  OBSERVER_CATEGORY(state, isObserver) {
    state.categoryObserver = isObserver
  },

  OBSERVER_PERIOD(state, isObserver) {
    state.periodObserver = isObserver
  },

  SET_SECTION(state, tabSections) {
    state.sectionTab = tabSections
  },

  SET_SESSION(state, tabSessions) {
    state.sessionTab = tabSessions
  },

  SET_TRAINING(state, tabTrainings) {
    state.trainingTab = tabTrainings
  },

  SET_TRAINER(state, tabTrainers) {
    state.trainerTab = tabTrainers
  },

  SET_TRAINEE(state, tabTrainees) {
    state.traineeTab = tabTrainees
  },

  SET_SURVEY(state, tabSurveys) {
    state.surveyTab = tabSurveys
  },

  ADD_SELECTED_FILTER(state, filterOption) {
    state.selectedFilter.find(option => option.label === filterOption.label) // Test finding the filter type "ex : document type"
      ? (state.selectedFilter.find(
          //If found, replace the list inside the option object (to update it)
          option => option.label === filterOption.label
        ).data = filterOption.data)
      : state.selectedFilter.push(filterOption) //If not found, push the filter and his list of element
  },

  SET_SORT_METHOD(state, sortMethod) {
    state.sortDocumentsMethod = sortMethod
  },

  REMOVE_FILTER(state, filterToRemove) {
    state.selectedFilter.splice(state.selectedFilter.indexOf(filterToRemove), 1)
  },

  RESET_SELECTED_FILTER(state) {
    state.selectedFilter = []
  },

  RESET_FILTER(state) {
    state.filter = {
      category: [],
      period: {},
      training: [],
      session: [],
      section: [],
      trainer: [],
      trainee: [],
      survey: []
    }
  },

  DISABLE_BUTTON(state, is_disabled) {
    state.disableButton = is_disabled
  }
}
