import DefaultLayout from '@/layouts/DefaultLayout'

export default [
  {
    path: '/account',
    name: 'AccountPage',
    meta: {
      title: 'account',
      layout: DefaultLayout
    },
    component: () => import('@/pages/AccountPage')
  }
]
