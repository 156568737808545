export default {
  SET_CURRENT_STATUS(state, newValue) {
    state.current_status = newValue
  },
  SET_OLD_STATUS(state, newValue) {
    state.old_status = newValue
  },
  SET_CURRENT_SURVEYS(state, newValue) {
    state.current_surveys = newValue
  },
  SET_CURRENT_EVALUATIONS(state, newValue) {
    state.current_evaluations = newValue
  },
  SET_CURRENT_SELECT_SURVEY(state, newValue) {
    state.current_select_survey = newValue
  },
  SET_CURRENT_SELECT_TRAINEE(state, newValue) {
    state.current_select_trainee = newValue
  },
  SET_CURRENT_SELECT_EVALUATION(state, newValue) {
    state.current_select_evaluation = newValue
  }
}
