import api from '@/api/api'

export default {
  getPdfSurvey({ commit, state }, { traineeId, surveyId }) {
    return api.auth
      .get(`trainee_registrations/${traineeId}/survey-pdf`, {
        base64: true,
        survey_id: surveyId
      })
      .then(data => {
        commit('SET_DATA_PDF', data)
        return Promise.resolve(data)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  getPdfSection({ commit, state }, { sectionId }) {
    return api.auth
      .get(`sections/${sectionId}/pdf`, {
        base64: true
      })
      .then(data => {
        commit('SET_DATA_PDF', data)
        return Promise.resolve()
      })
      .catch(e => {
        return Promise.reject(e)
      })
  }
}
